import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { getInstrumentCurrentStatusBadgeData } from '@/components/experiment/StatusesInfo/helpers';
import StatusBadge from '@/components/experiment/StatusesInfo/StatusBadge';

import { MDASH } from '@/helpers';

import { formatDateTime } from '@/pages/Home/helpers';

import styles from './InstrumentPageHeader.module.scss';

const cn = classnames.bind(styles);

type TInstrumentPageHeaderProps = {
  instrument?: TInstrumentFromServer;
  className?: string;
};

const InstrumentPageHeader: FC<TInstrumentPageHeaderProps> = ({ instrument, className }) => {
  const [searchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? null;

  const badgeData = useMemo(
    () => (instrument?.currentStatus ? getInstrumentCurrentStatusBadgeData(instrument.currentStatus) : null),
    [instrument?.currentStatus]
  );

  return (
    <div className={cn('instrument', className)}>
      <h2 className={cn('instrument__name')}>{instrumentName ?? instrument?.name}</h2>
      <div className={cn('instrument__details')}>
        <div className={cn('instrument__details-block')}>
          <div className={cn('instrument__row')}>
            <span>Operator</span>
            <span>{instrument?.currentStatus?.operator ?? MDASH}</span>
          </div>
          <div className={cn('instrument__row')}>
            <span>SW Version</span>
            <span>{instrument?.currentStatus?.version ?? MDASH}</span>
          </div>
        </div>
        <div className={cn('instrument__details-block')}>
          <div className={cn('instrument__row')}>
            <span>Status</span>
            {badgeData ? <StatusBadge className={cn('instrument__status')} {...badgeData} /> : <span>{MDASH}</span>}
          </div>
          <div className={cn('instrument__row')}>
            <span>Run start</span>
            <span>{formatDateTime(instrument?.currentStatus?.timestamp, 'hh:mm:ss, MMM d yyyy') ?? MDASH}</span>
          </div>
        </div>
        {instrument?.currentStatus?.experiment?.name && (
          <div className={cn('instrument__details-block')}>
            <div className={cn('instrument__row')}>
              <span>Run name</span>
              <span>{instrument?.currentStatus?.experiment?.name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(InstrumentPageHeader);
