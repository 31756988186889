import { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { DocumentNode, useLazyQuery } from '@apollo/client';

import { getErrorMessage } from '@/helpers/errors';
import { useDebounce } from '../useDebounce';
import { TApolloResponse, TFetchTokenResult } from './types';

const usePaginatedQuery = <T>(
  query: DocumentNode,
  variables: Record<string, Nullable<string | number>>,
  select: (data: TFetchTokenResult<T>) => TApolloResponse<T>,
  key: string,
  isLazyFetch?: boolean
) => {
  const [isQueryInProgress, setIsQueryInProgress] = useState(false);

  const [fetchData, { data, loading, error, fetchMore }] = useLazyQuery(query);

  const response = useMemo(() => (data ? select(data) : null), [data]);
  const nextToken = useMemo(() => response?.nextToken ?? null, [response?.nextToken]);

  const loadMore = useCallback(async () => {
    if (!response || isQueryInProgress) return;

    if (nextToken) {
      setIsQueryInProgress(true);

      try {
        await fetchMore({
          variables: { ...variables, startToken: nextToken },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const prevResultResponse: TApolloResponse<T> = select(prevResult);
            const fetchMoreResultResponse: TApolloResponse<T> = select(fetchMoreResult);

            if (fetchMoreResultResponse?.nextToken === prevResultResponse?.nextToken) {
              return prevResult;
            }

            const items = prevResultResponse?.items ?? [];

            return {
              [key]: {
                ...fetchMoreResultResponse,
                items: [...items, ...fetchMoreResultResponse.items],
              },
            };
          },
        }).then(() => {
          setIsQueryInProgress(false);
        });
      } catch (err) {
        toast.error(getErrorMessage(err));
        setIsQueryInProgress(false);
      }
    }
  }, [nextToken, fetchMore, select, variables, key, isQueryInProgress]);

  useEffect(() => {
    if (isLazyFetch) return;

    fetchData({
      variables: { ...variables, nextToken: null },
      notifyOnNetworkStatusChange: true,
    });
  }, []);

  useEffect(() => {
    if (response?.nextToken) {
      loadMore();
    }
  }, [response?.nextToken, loadMore]);

  const debouncedIsListLoading = useDebounce(loading);

  return useMemo(
    () => ({
      allItems: response?.items ?? [],
      loading: debouncedIsListLoading,
      error,
      fetchData,
    }),
    [response?.items, debouncedIsListLoading, error, fetchData]
  );
};

export default usePaginatedQuery;
