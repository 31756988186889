import { FC, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import NumberInput from '@/components/common/NumberInput';
import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';
import { addTooltip } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';
import { CagingSettingsItem } from '@/graphql/API';
import { themeOptions } from '@/types/theme';

import styles from './SettingInput.module.scss';
import SettingsInfoPopover from '../../../SettingsInfoPopover';
import { TSettingsValidationConfig } from '../../types';

const cn = classnames.bind({ ...styles });

const DEFAULT_POPOVER_MESSAGE =
  'The default CCE settings listed below have been manually modified in at least one of the lanes:';

type TSettingInput = {
  onChangeNumberField: (field: TCagingSettingNumberField) => (n: number) => void;
  isMixed?: boolean;
  value: number;
  field: TCagingSettingNumberField;
  errors: Partial<Record<string | number, string>>;
  defaultValue?: number;
  settings?: Nullable<CagingSettingsItem>;
  isEditMode?: boolean;
  popoverTitle?: string;
  cageSettingsInputValidationConfig?: Nullable<Record<TCagingSettingNumberField, TSettingsValidationConfig>>;
  theme?: themeOptions;
  className?: string;
  popoverMessage?: string;
};

const SettingInput: FC<TSettingInput> = ({
  onChangeNumberField,
  isMixed,
  value,
  field,
  errors,
  defaultValue,
  settings,
  isEditMode,
  cageSettingsInputValidationConfig,
  theme,
  className,
  popoverMessage = DEFAULT_POPOVER_MESSAGE,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isChanged = useMemo(
    () => isDefined(defaultValue) && defaultValue !== settings?.[field],
    [defaultValue, settings, field]
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={cn('input-container', className)} {...addTooltip(errors[field])}>
      {!isFocused && isMixed && <div className={cn('input-container__mixed-layout')}>Mixed</div>}
      {(isChanged || isMixed) && (
        <SettingsInfoPopover
          title={popoverMessage}
          className={cn('input-container__popover')}
          changedSettings={
            defaultValue && isMixed
              ? {
                  [field]: defaultValue,
                }
              : {}
          }
          cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
        />
      )}
      <NumberInput
        onChange={onChangeNumberField(field)}
        value={value}
        inputClassName={cn('input', {
          input_mixed: isMixed,
          input_focused: isFocused,
          input_light: theme === themeOptions.light,
          input_changed: isChanged,
          input_disabled: !isEditMode,
          error: errors[field],
        })}
        min={-1000}
        max={10000}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default SettingInput;
