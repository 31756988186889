// this is an auto generated file. This will be overwritten
import { gql } from '@apollo/client';

import * as APITypes from './API';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const dummy = /* GraphQL */ `query Dummy {
  dummy
}
` as GeneratedQuery<APITypes.DummyQueryVariables, APITypes.DummyQuery>;
export const runDesignTemplates = /* GraphQL */ `query RunDesignTemplates($input: ListRunDesignTemplatesInput) {
  runDesignTemplates(input: $input) {
    edges {
      node {
        id
        name
        description
        authorId
        activeVersionId
          activeVersion {
            __typename
            id
            templateId
            schema {
              __typename
              components {
              __typename
              name
              type
              }
            }
          }
        createdAt
        updatedAt
        deprecatedAt
      }
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignTemplatesQueryVariables, APITypes.RunDesignTemplatesQuery>;
export const runDesignTemplate = /* GraphQL */ `query RunDesignTemplate($id: ID!) {
  runDesignTemplate(id: $id) {
    id
    name
    description
    authorId
    activeVersionId
    activeVersion {
      id
      templateId
      description
      authorId
      version
      createdAt
      updatedAt
      publishedAt
      deprecatedAt
      __typename
    }
    createdAt
    updatedAt
    deprecatedAt
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignTemplateQueryVariables, APITypes.RunDesignTemplateQuery>;
export const runDesignTemplateVersions =
  /* GraphQL */ `query RunDesignTemplateVersions($input: ListRunDesignTemplateVersionsInput!) {
  runDesignTemplateVersions(input: $input) {
    edges {
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignTemplateVersionsQueryVariables, APITypes.RunDesignTemplateVersionsQuery>;
export const runDesignTemplateVersion = /* GraphQL */ `query RunDesignTemplateVersion($id: ID!) {
  runDesignTemplateVersion(id: $id) {
    id
    templateId
    description
    authorId
    version
    createdAt
    updatedAt
    publishedAt
    deprecatedAt
    schema {
      __typename
    }
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignTemplateVersionQueryVariables, APITypes.RunDesignTemplateVersionQuery>;
export const runDesign = /* GraphQL */ `query RunDesign($id: ID!) {
  runDesign(id: $id) {
    organizationId
    id
    name
    description
    investigatorId
    projectId
    externalLinks
    organisms
    createdAt
    updatedAt
    publishedAt
    templateVersionId
    wizardStep
    flowcellType
    schema {
      __typename
       components {
        __typename
        id
        type
        name
        duration
        pauseAfterCompletion
        timing {
          __typename
          placement
          relativeTo
          offsetInSeconds
        }
        performedOnLanes {
          __typename
          laneId
          consumables {
             __typename
            volume
            concentration
            duration
            consumable {
              __typename
              id
              name
              type
              annotations
              ... on Reagent {
                detectionWavelength
                excitationWavelength
                marker
                phenotype
                opticalDisplay {
                  __typename
                  excitation {
                      __typename
                    symbol
                    name
                    hex
                  }
                  detection {
                    __typename
                    symbol
                    name
                    hex
                  }
                }
              }
              ... on Stain {
                detectionWavelength
                excitationWavelength
                marker
                phenotype
                opticalDisplay {
                  __typename
                  excitation {
                      __typename
                    symbol
                    name
                    hex
                  }
                  detection {
                    __typename
                    symbol
                    name
                    hex
                  }
                }
              }
            }
          }
        }
        tags {
          __typename
          key
          value
        }
        editableProperties {
          __typename
          path
        }
        ... on CytokineSecretion {
          pauseAfterScan
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
        }
        ... on SurfaceReceptorCytokineSecretion {
          pauseAfterScan
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
        }
        ... on CellKilling {
          pauseAfterScan
          deliveryStainsAt
          baselineScan
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
        }
        ... on SurfaceReceptor {
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
        }
        ... on CellCaging {
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
        }
        ... on SelectiveRetention {
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
        }
        ... on ScanComp {
          partOf
        }
        ... on Incubation {
          hasTreatment
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
          performedOnLanesWithMedia {
            __typename
              media
              laneId
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
        }
        ... on Treatment {
          pauseAfterScan
          scanConfig {
            __typename
            numberOfScans
            lagTime
            scanEvery
            scanIds
          }
          wash {
            __typename
            duration
            temperature
            temperatureUnit
            numberOfWashes
          }
          incubation {
            __typename
            duration
            temperature
            temperatureUnit
            onDevice
          }
        }
      }
      lanes {
        __typename
        id
        sample
        annotation
        cellTypes {
          __typename
          function
          otherFunction
          cellIndex {
            __typename
            id
            name
            cellLineOrPrimary
            organisms
            morphology
            tissue
            phenotype
            growthProperties
            vendor
          }
          preTreatment {
            __typename
            volume
            concentration
            duration
            consumable {
              __typename
              id
              name
              type
              annotations
              ... on Reagent {
                detectionWavelength
                excitationWavelength
                marker
                phenotype
                opticalDisplay {
                  __typename
                  excitation {
                      __typename
                    symbol
                    name
                    hex
                  }
                  detection {
                    __typename
                    symbol
                    name
                    hex
                  }
                }
              }
            }
          }
          preLabelings {
            __typename
            volume
            concentration
            duration
            consumable {
              __typename
              id
              name
              type
              annotations
              ... on Reagent {
                detectionWavelength
                excitationWavelength
                marker
                phenotype
                opticalDisplay {
                  __typename
                  excitation {
                      __typename
                    symbol
                    name
                    hex
                  }
                  detection {
                    __typename
                    symbol
                    name
                    hex
                  }
                }
              }
            }
          }
        }
      }
      cagingSettings {
        __typename
        global {
          __typename
          cageRadius
          cageWallThickness
          cageToCageDistance
          cellToCageWallMinDistance
          cageWallToOtherObjsMinDistance
          minCellSize
          maxCellSize
          maxNumberOfControlCages
          maxCageAreaFraction
          dmdExposure
          dmdIntensity
          autoFocusCurrent
          autoFocusExposureTime
          zOffset
        }
        perLane {
          __typename
          laneId
          magnification
          cceType
          cellToCage {
            __typename
            name
            function
            otherFunction
            cellIndex {
              __typename
              id
              name
              cellLineOrPrimary
              organisms
              morphology
              tissue
              phenotype
              growthProperties
              vendor
            }
            preTreatment {
              __typename
              consumable {
                __typename
                id
                name
                type
                annotations
                ... on Reagent {
                  detectionWavelength
                  excitationWavelength
                  marker
                  phenotype
                  opticalDisplay {
                    __typename
                    excitation {
                       __typename
                      symbol
                      name
                      hex
                    }
                    detection {
                      __typename
                      symbol
                      name
                      hex
                    }
                  }
                }
              }
              volume
              concentration
              duration
            }
            preLabelings {
              __typename
              consumable {
                __typename
                id
                name
                type
                annotations
                ... on Reagent {
                  detectionWavelength
                  excitationWavelength
                  marker
                  phenotype
                  opticalDisplay {
                    __typename
                    excitation {
                       __typename
                      symbol
                      name
                      hex
                    }
                    detection {
                      __typename
                      symbol
                      name
                      hex
                    }
                  }
                }
              }
              volume
              concentration
              duration
            }
          }
          cellToSubtract {
            __typename
            name
            cellIndex {
              __typename
              id
              name
              cellLineOrPrimary
              organisms
              morphology
              tissue
              phenotype
              growthProperties
              vendor
            }
            function
            otherFunction
            preTreatment {
              __typename
              consumable {
                __typename
                id
                name
                type
                annotations
                ... on Reagent {
                  detectionWavelength
                  excitationWavelength
                  marker
                  phenotype
                  opticalDisplay {
                    __typename
                    excitation {
                       __typename
                      symbol
                      name
                      hex
                    }
                    detection {
                      __typename
                      symbol
                      name
                      hex
                    }
                  }
                }
              }
              volume
              concentration
              duration
            }
            preLabelings {
              __typename
              consumable {
                __typename
                id
                name
                type
                annotations
                ... on Reagent {
                  detectionWavelength
                  excitationWavelength
                  marker
                  phenotype
                  opticalDisplay {
                    __typename
                    excitation {
                       __typename
                      symbol
                      name
                      hex
                    }
                    detection {
                      __typename
                      symbol
                      name
                      hex
                    }
                  }
                }
              }
              volume
              concentration
              duration
            }
          }
          overrideSettings {
            __typename
            cageRadius
            cageWallThickness
            cageToCageDistance
            cellToCageWallMinDistance
            cageWallToOtherObjsMinDistance
            minCellSize
            maxCellSize
            maxNumberOfControlCages
            maxCageAreaFraction
            dmdExposure
            dmdIntensity
            autoFocusCurrent
            autoFocusExposureTime
            zOffset
          }
        }
      }
      opticsSettings {
        __typename
        global {
          __typename
          intensity
          exposure
          detectionWavelength
          excitationWavelength
          zOffset
          opticalDisplay {
            __typename
            excitation {
              __typename
              symbol
              name
              hex
            }
            detection {
              __typename
              symbol
              name
              hex
            }
          }
        }
        advanced {
          __typename
          laneId
          scanId
          settings {
            __typename
            detectionWavelength
            excitationWavelength
            intensity
            exposure
            zOffset
            opticalDisplay {
              __typename
              excitation {
                  __typename
                symbol
                name
                hex
              }
              detection {
                __typename
                symbol
                name
                hex
              }
            }
          }
        }
      }
    }
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignQueryVariables, APITypes.RunDesignQuery>;
export const runDesigns = /* GraphQL */ `query RunDesigns($input: ListRunDesignsInput) {
  runDesigns(input: $input) {
    edges {
      node {
        organizationId
        id
        name
        description
        investigatorId
        projectId
        createdAt
        updatedAt
        publishedAt
        templateVersionId
        wizardStep
        schema {
          components {
            __typename
            id
            type
            performedOnLanes {
              laneId
              consumables {
                __typename
              }
            }
          }
          lanes {
            id
          }
          __typename
        }
      }
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.RunDesignsQueryVariables, APITypes.RunDesignsQuery>;
export const jobDefinitions = /* GraphQL */ `query JobDefinitions($input: ListJobDefinitionsInput) {
  jobDefinitions(input: $input) {
    edges {
      __typename
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.JobDefinitionsQueryVariables, APITypes.JobDefinitionsQuery>;
export const jobDefinition = /* GraphQL */ `query JobDefinition($id: ID!) {
  jobDefinition(id: $id) {
    id
    name
    createdAt
    updatedAt
    revisions {
      number
      createdAt
      executor
      jobRoleArn
      timeoutInSeconds
      ephemeralStorageInGiB
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.JobDefinitionQueryVariables, APITypes.JobDefinitionQuery>;
export const jobRuns = /* GraphQL */ `query JobRuns($input: ListJobRunsInput) {
  jobRuns(input: $input) {
    edges {
      node {
        organizationId
        id
        name
        status
        createdAt
        jobDefinition {
          id
          revision
        }
        containerOverrides {
          command
        }
        updatedAt
        userId
        cancelReason
        logUrl
      }
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.JobRunsQueryVariables, APITypes.JobRunsQuery>;
export const jobRun = /* GraphQL */ `query JobRun($id: ID!) {
  jobRun(id: $id) {
    organizationId
    id
    name
    jobDefinition {
      id
      revision
      __typename
    }
    containerOverrides {
      command
      __typename
    }
    status
    createdAt
    updatedAt
    startedAt
    endedAt
    timeoutInSeconds
    userId
    cancelReason
    logUrl
    tags {
      key
      value
      __typename
    }
    dependsOn
    rerunOf
    __typename
  }
}
` as GeneratedQuery<APITypes.JobRunQueryVariables, APITypes.JobRunQuery>;
export const jobRunLogs = /* GraphQL */ `query JobRunLogs($input: ListJobRunLogsInput) {
  jobRunLogs(input: $input) {
    edges {
      node {
        ingestionTime
        message
        timestamp
      }
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.JobRunLogsQueryVariables, APITypes.JobRunLogsQuery>;
export const auditLog = /* GraphQL */ `query AuditLog($id: ID!) {
  auditLog(id: $id) {
    id
    action
    description
    details
    organizationId
    service
    scope
    correlationId
    occurredAt
    registeredAt
    actor {
      type
      id
      name
      metadata
      __typename
    }
    metadata
    details
    __typename
  }
}
` as GeneratedQuery<APITypes.AuditLogQueryVariables, APITypes.AuditLogQuery>;
export const auditLogs = /* GraphQL */ `query AuditLogs($input: AuditLogsFilter) {
  auditLogs(input: $input) {
    edges {
      node {
        action
        actor {
          metadata
          id
          name
          type
        }
        correlationId
        description
        details
        id
        metadata
        occurredAt
        organizationId
        registeredAt
        scope
        service
      }
      __typename
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.AuditLogsQueryVariables, APITypes.AuditLogsQuery>;

export const telemetryByInstrument = gql`
  query TelemetryByInstrument($instrumentId: ID!) {
    telemetryByInstrument(instrumentId: $instrumentId) {
      name
      label
      description
      postfix
      time
      type
      value
    }
  }
`;

export const instruments = gql`
  query Instruments($limit: Int) {
    instruments(limit: $limit) {
      id
      name
      agent {
        id
        connectivity {
          connected
          disconnectedReason
          lastReportTime
        }
      }
      organization {
        id
      }
      serialNumber
      currentStatus {
        status
        numPendingChores
        message
        operator
        version
        timestamp
        experiment {
          id
          name
          type
        }
      }
    }
  }
`;

export const instrumentById = gql`
  query InstrumentById($instrumentId: ID!) {
    instrumentById(instrumentId: $instrumentId) {
      id
      currentStatus {
        status
        timestamp
        version
        operator
        numPendingChores
        message
        experiment {
          id
          name
          type
        }
      }
      name
      serialNumber
      organization {
        id
      }
    }
  }
`;

export const telemetryBySensor = gql`
  query TelemetryBySensor(
    $instrumentId: ID!
    $name: String!
    $limit: Int
    $startDate: String
    $endDate: String
    $startToken: String
  ) {
    telemetryBySensor(
      instrumentId: $instrumentId
      name: $name
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      startToken: $startToken
    ) {
      items {
        time
        name
        label
        type
        value
        description
        postfix
      }
      limit
      totalCount
      nextToken
    }
  }
`;
