import { FC, memo, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';

import { useOpenModal } from '@/hooks/useOpenModal';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';
import { withRunDesignCagingSettingsContext } from '@/hoc/withRunDesignCagingSettingsContext';

import { RunDesignContext } from '@/pages/experiment-run-design/context';

import { experimentRunDesignSelectors, TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import SettingsCard from './components/SettingsCard';
import CustomPageHeader from './components/CustomPageHeader';
import CageSettingsInfoModal from './components/CageSettingsInfoModal';
import GlobalSettings from './components/GlobalSettings';
import SettingsModal from '../DesignTimeline/components/SettingsModal';
import { editReducer } from '../DesignTimeline/reducer';
import { TEditReducerAction } from '../DesignTimeline/reducer/types';

const CagingSettings: FC = () => {
  const { overrideCustomFooterConfig, clearCustomFooterConfig, addCustomHeaderContent, clearCustomHeaderContent } =
    useContext(RunDesignContext);
  const {
    resetLaneSettingsToGlobal,
    newLaneCagingSettings,
    saveNewCagingSettings,
    resetAllLastChanges,
    isGlobalSettingsMode,
    switchGlobalSettingsMode,
  } = useCagingSettingsContext();

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);
  const cellCagingComponent = useSelector(experimentRunDesignSelectors.selectComponentByTypeName('CellCaging'));
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);

  const cardRef = useRef(null);

  const [currentComponentId, setCurrentComponentId] = useState<Nullable<string>>(null);

  const {
    isOpen: isTimelineSettingsModalOpen,
    setIsOpen: setIsTimelineSettingsModalOpen,
    onClose: onTimelineSettingsModalClose,
  } = useOpenModal();

  const showResetBtn = useMemo(() => {
    if (!newLaneCagingSettings) return false;

    const res = newLaneCagingSettings?.some(
      (laneInfo) => laneInfo && !isEqual(laneInfo.overrideSettings, globalCagingSettings)
    );

    return res;
  }, [newLaneCagingSettings, globalCagingSettings]);

  const openTimelineSettingsModal = useCallback(() => {
    if (!cellCagingComponent) return;
    setCurrentComponentId(cellCagingComponent.id);
    setIsTimelineSettingsModalOpen(true);
  }, [cellCagingComponent]);
  const handleChangeCurrentComponentId = (id: string) => setCurrentComponentId(id);

  const [fullComponentsList, dispatchComponentListAction] = useReducer<
    Reducer<Nullable<TRunDesignComponent[]>, TEditReducerAction>
  >(editReducer, null);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: cardRef,
      isEditMode: runDesignCardIndexEdit >= 0,
      saveAndContinue: {
        clickHandler: saveNewCagingSettings,
      },
      cancel: {
        clickHandler: resetAllLastChanges,
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [
    overrideCustomFooterConfig,
    clearCustomFooterConfig,
    runDesignCardIndexEdit,
    saveNewCagingSettings,
    resetAllLastChanges,
  ]);

  const onResetSettingsClick = () => resetLaneSettingsToGlobal();

  useEffect(() => {
    if (!addCustomHeaderContent || !clearCustomHeaderContent) {
      return;
    }

    addCustomHeaderContent(
      <CustomPageHeader
        onClick={switchGlobalSettingsMode}
        isActive={isGlobalSettingsMode}
        isEditMode={runDesignCardIndexEdit >= 0}
        openTimelineSettingsModal={openTimelineSettingsModal}
        isGlobalSettingsMode={isGlobalSettingsMode}
        resetLaneSettingsToDefault={showResetBtn ? onResetSettingsClick : undefined}
      />
    );

    return () => {
      clearCustomHeaderContent();
    };
  }, [
    addCustomHeaderContent,
    clearCustomHeaderContent,
    runDesignCardIndexEdit,
    isGlobalSettingsMode,
    switchGlobalSettingsMode,
    isGlobalSettingsMode,
    showResetBtn,
    resetLaneSettingsToGlobal,
  ]);

  return (
    <>
      <CageSettingsInfoModal />
      <RunDesignBlockWithLanes hideLanesBlock={isGlobalSettingsMode} title="">
        {isGlobalSettingsMode ? (
          <GlobalSettings cardRef={cardRef} openTimelineSettingsModal={openTimelineSettingsModal} />
        ) : (
          <SettingsCard innerRef={cardRef} />
        )}
      </RunDesignBlockWithLanes>
      <SettingsModal
        open={isTimelineSettingsModalOpen}
        currentComponentId={currentComponentId}
        setCurrentComponentId={handleChangeCurrentComponentId}
        onClose={onTimelineSettingsModalClose}
        componentList={fullComponentsList}
        dispatchComponentListAction={dispatchComponentListAction}
      />
    </>
  );
};

export default memo(withRunDesignCagingSettingsContext(CagingSettings));
