export const EAnnotationType = {
  bead: 'bead',
  cellIndex: 'cellIndex',
  reagent: 'reagent',
  marker: 'marker',
  panel: 'panel',
  stain: 'stain',
  compound: 'compound',
  flowcellType: 'flowcellType',
} as const;

export type TAnnotationType = keyof typeof EAnnotationType;
