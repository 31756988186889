import { z } from 'zod';

export const updateRunDesignSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  investigatorId: z.string().optional(),
  projectId: z.string().optional(),
  externalLinks: z.string().array().optional(),
  organisms: z.string().array().optional(),
});

export const createRunDesignFromTemplateSchema = z.object({
  templateId: z.string().optional(),
  name: z.string({ errorMap: () => ({ message: 'Please enter a design name' }) }).min(1, 'Please enter a design name'),
  description: z.string().optional(),
  investigatorId: z
    .string({ errorMap: () => ({ message: 'Please select an investigator' }) })
    .min(1, 'Please select an investigator'),
  projectId: z.string({ errorMap: () => ({ message: 'Please select a project' }) }).min(1, 'Please select a project'),
  externalLinks: z.string().array().optional(),
  organisms: z.string().array().optional(),
});
