import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { getDefaultWavesConfig } from '@/helpers/waveLengths';
import { DecimalRegex } from '@/helpers/validator';
import { MDASH } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { experimentRunDesignSelectors, TMatrixSettingItem } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';
import NumberInput from '@/components/common/NumberInput';
import WaveChannel from '@/components/common/WaveChannel';
import NoDataFound from '@/components/common/NoDataFound';
import { getScanOrdersMap } from '@/helpers/runDesigns/timeline';

import styles from '../MatrixView/MatrixView.module.scss';
import { defineIsTheFirstSettingElement, defineIsTheLastSettingElement } from '../../helpers';
import InfoPopover from '../InfoPopover';
import { DEFAULT_EXPOSURE, DEFAULT_INTENSITY, DEFAULT_ZOFFSET } from '../../constants';

const cn = classnames.bind(styles);

const HEADER_COLUMNS = 9;

type MatrixViewTable = {
  lanesMatrixSettings: TMatrixSettingItem[];
  activeLaneId: Nullable<string>;
  updateSettings: ({
    value,
    key,
    laneId,
    scanId,
    consumableId,
  }: {
    value: number;
    key?: string;
    laneId: string;
    scanId?: string;
    consumableId: string;
  }) => void;
};

const MatrixViewTable: FC<MatrixViewTable> = ({ lanesMatrixSettings, updateSettings, activeLaneId }) => {
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);
  const componentList = useSelector(experimentRunDesignSelectors.selectComponentList);
  const scanOrdersMap = useMemo(() => getScanOrdersMap(componentList ?? []), [componentList]);

  if (!opticalMatrixSettings.some((el) => el.scanConfig)) {
    return (
      <NoDataFound
        size="big"
        textData="There are no optic settings for matrix view or no components with scan"
        className="center-block"
      />
    );
  }

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('matrix-view__table')}
      bodyClassName={cn('matrix-view__body')}
      headerClassName={cn('matrix-view__header')}
      header={
        <>
          <RunDesignTable.Row>
            <RunDesignTable.Column colspan={HEADER_COLUMNS}>
              <div className={cn('matrix-view__sub-header')}>{activeLaneId}</div>
            </RunDesignTable.Column>
          </RunDesignTable.Row>
          <RunDesignTable.Row>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>SCAN </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>EX. CHANNEL </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>EM. FILTER </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>
              <div className={cn('matrix-view__header-with-info')}>
                <span>INTENSITY</span> <InfoPopover title="Intensity range" range="1 - 100" />
              </div>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>
              <div className={cn('matrix-view__header-with-info')}>
                <span>EXP(MS)</span> <InfoPopover title="Exposure range (ms)" range="1 - 5000" />
              </div>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>
              <div className={cn('matrix-view__header-with-info')}>
                <span>Z-OFFSET </span> <InfoPopover title="Z-offset range" range="0.1 - 100" />
              </div>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col')}>
              <span />
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col', 'matrix-view__header-col_reagent')}>
              REAGENT/CELL TYPE
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__header-col', 'matrix-view__header-col_assay')}>
              ASSAY{' '}
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        </>
      }
    >
      {lanesMatrixSettings.map((row, index) => {
        const isTheFirstScanSection = defineIsTheFirstSettingElement(row, lanesMatrixSettings, index, activeLaneId);
        const isTheLastScanSection = defineIsTheLastSettingElement(row, lanesMatrixSettings, index, activeLaneId);

        const onInputChange = (value: number, key?: string) => {
          updateSettings({
            value,
            key,
            laneId: row.laneId,
            scanId: row.scanConfig?.scanIds?.[0],
            consumableId: row.consumable.id,
          });
        };

        const exColor = row.opticalDisplay?.excitation?.hex ?? getDefaultWavesConfig().ex.color;
        const emColor = row.opticalDisplay?.detection?.hex ?? getDefaultWavesConfig().em.color;
        const exLabel = row.opticalDisplay?.excitation?.name ?? getDefaultWavesConfig().ex.uiLabel;
        const emLabel = row.opticalDisplay?.detection?.symbol ?? getDefaultWavesConfig().em.label;

        return (
          <RunDesignTable.Row
            key={row.id}
            className={cn('matrix-view__row', {
              'matrix-view__row_start-section': isTheFirstScanSection,
              'matrix-view__row_end-section': isTheLastScanSection,
            })}
          >
            <RunDesignTable.Column>
              <span className={cn('scan')}>{scanOrdersMap?.[row?.componentId ?? ''] ?? MDASH}</span>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <WaveChannel.Item color={exColor} className={cn('matrix-view__wave-channel')}>
                {exLabel}
              </WaveChannel.Item>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <WaveChannel.Item color={emColor} className={cn('matrix-view__wave-channel')}>
                {emLabel}
              </WaveChannel.Item>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <NumberInput
                className={cn('matrix-view__input', {
                  'matrix-view__input_changed': isDefined(row.intensity) && row.intensity !== DEFAULT_INTENSITY,
                })}
                value={row.intensity ?? DEFAULT_INTENSITY}
                min={0}
                max={100}
                customRegex={DecimalRegex}
                name="intensity"
                onChange={onInputChange}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <NumberInput
                className={cn('matrix-view__input', {
                  'matrix-view__input_changed': isDefined(row.exposure) && row.exposure !== DEFAULT_EXPOSURE,
                })}
                value={row.exposure ?? DEFAULT_EXPOSURE}
                min={0}
                max={5000}
                customRegex={DecimalRegex}
                name="exposure"
                onChange={onInputChange}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <NumberInput
                className={cn('matrix-view__input', {
                  'matrix-view__input_changed': isDefined(row.zOffset) && row.zOffset !== DEFAULT_ZOFFSET,
                })}
                value={row?.zOffset ?? DEFAULT_ZOFFSET}
                min={0}
                max={100}
                customRegex={DecimalRegex}
                name="zOffset"
                onChange={onInputChange}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <span className={cn('arrow')}>
                <icons.ArrowPointerIcon />
              </span>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__col')}>
              <div
                className={cn('content-wrapper', {
                  'content-wrapper_top-rounded': isTheFirstScanSection || index === 0,
                  'content-wrapper_bottom-rounded': isTheLastScanSection || index === lanesMatrixSettings.length - 1,
                })}
              >
                <ConsumableComponent consumable={row.consumable} />
              </div>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('matrix-view__col')}>
              <div
                className={cn('assay-wrapper', {
                  'assay-wrapper_top-rounded': isTheFirstScanSection || index === 0,
                  'assay-wrapper_bottom-rounded': isTheLastScanSection || index === lanesMatrixSettings.length - 1,
                })}
              >
                <span className={cn('assay')}>
                  <span className={cn('assay__icon')}>{row.assayIcon ?? MDASH}</span>
                  <span className={cn('assay__title')}>{row.componentName}</span>
                </span>
              </div>
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default MatrixViewTable;
