import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { arrToMapByKeys, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors, TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import AddInformation from '@/components/runDesign/AddInformation';
import RunDesignCard from '@/components/runDesign/RunDesignCard';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { LaneConsumablesWithMedia } from '@/graphql/API';

import { ReagentListContext } from './context';
import { TReagentListByLaneList } from './types';
import { getDurationText, getTemperatureText } from './helpers';

import EditReagents from './components/EditReagents';

import styles from './ReagentsCard.module.scss';

const cn = classnames.bind(styles);

type TReagentsCard = {
  className?: string;
  component: TRunDesignComponent;
  handleExpandMode: (index: number) => void;
  handleEditMode: (index: number) => void;
  runDesignCardIndex: number;
  isExpandMode?: boolean;
  isEditMode?: boolean;
  withWaves?: boolean;
  openSettingsModal?: (id: string) => void;
};

const ReagentsCard: FC<TReagentsCard> = ({
  className,
  component,
  runDesignCardIndex,
  handleEditMode,
  handleExpandMode,
  isEditMode,
  isExpandMode,
  withWaves,
  openSettingsModal,
}) => {
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const savedMediaByLaneMap = useMemo<Record<string, LaneConsumablesWithMedia>>(() => {
    if (!isIncubationComponent(component)) return {};

    const byLanesMap = arrToMapByKeys(component?.performedOnLanesWithMedia ?? [], 'laneId');
    return byLanesMap;
  }, [component]);

  const savedReagentListByLaneList = useMemo<TReagentListByLaneList>(() => {
    const byLanesMap = arrToMapByKeys(component?.performedOnLanes ?? [], 'laneId');

    const usedReagentList: TReagentListByLaneList = LANE_LETTER_NAME_LIST.map((laneLetter) => {
      const laneData = byLanesMap?.[laneLetter];

      if (!laneData?.consumables) {
        return {
          componentId: component?.id ?? '',
          laneLetter,
          reagents: [],
        };
      }

      return {
        componentId: component?.id ?? '',
        laneLetter,
        reagents: laneData?.consumables ?? [],
      };
    });

    return usedReagentList;
  }, [component]);

  const [currentReagentListByLaneList, setCurrentReagentListByLaneList] = useState<TReagentListByLaneList>(
    structuredClone(savedReagentListByLaneList)
  );

  const [mediaSettingsByLaneMap, setMediaSettingsByLaneMap] = useState<Record<string, LaneConsumablesWithMedia>>({
    ...savedMediaByLaneMap,
  });

  const countReagentColumns = useMemo(() => {
    if (currentReagentListByLaneList.length === 0) {
      return 0;
    }
    return Math.max(
      ...currentReagentListByLaneList.map(
        (reagentListByLane) => reagentListByLane?.reagents?.filter((reagent) => !!reagent)?.length ?? 0
      )
    );
  }, [currentReagentListByLaneList]);

  const isIncubation = useMemo(() => isIncubationComponent(component), [component?.type]);

  const hasFilledDetails = useMemo(
    () =>
      component?.performedOnLanes?.some((el) => el.consumables?.filter?.((consumable) => !!consumable)?.length) ||
      isIncubation,
    [component?.performedOnLanes]
  );

  const shouldDisplayAddInformation = useMemo(
    () => !isEditMode && !hasFilledDetails,
    [isEditMode, component?.performedOnLanes]
  );

  const incubationData = useMemo(() => {
    if (isIncubationComponent(component) && !!component.incubation) {
      const { onDevice, temperature, temperatureUnit, duration } = component.incubation;
      return {
        deviceText: onDevice ? 'On-device' : 'Off-device',
        temperature: getTemperatureText(temperature, temperatureUnit),
        duration: getDurationText(duration),
      };
    }
    return null;
  }, [component]);

  const changeEditMode = useCallback(() => {
    handleEditMode?.(runDesignCardIndex);
  }, [handleEditMode, runDesignCardIndex]);

  const changeExpandMode = useCallback(() => {
    handleExpandMode?.(runDesignCardIndex);
  }, [handleExpandMode, runDesignCardIndex]);

  const handleIncubationDataClick = useCallback(() => {
    if (!component.id || !openSettingsModal) return;

    openSettingsModal(component.id);
  }, [openSettingsModal, component.id]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleEditMode?.(runDesignCardIndex);
    },
    [isEditMode, runDesignCardIndex]
  );

  useEffect(() => {
    setCurrentReagentListByLaneList(structuredClone(savedReagentListByLaneList));
  }, [savedReagentListByLaneList, someRunDesignCardIsEdit]);

  return (
    <ReagentListContext.Provider value={currentReagentListByLaneList}>
      <RunDesignCard className={cn(className)}>
        <RunDesignCard.Header
          isExpandMode={isExpandMode}
          hasDetails={hasFilledDetails}
          onExpandClick={changeExpandMode}
          onSettingClick={changeEditMode}
          isEditMode={isEditMode}
        >
          <div className={cn('assay-reagents__wrapper')}>
            <span className={cn('assay-reagents__name', { 'assay-reagents__name_incubation': isIncubation })}>
              {component?.name ?? 'Assay reagents'}
            </span>
            {!isIncubation && (
              <span className={cn('assay-reagents__badge', 'assay-reagents__badge_counter')}>
                {countReagentColumns}
              </span>
            )}

            {isIncubation && incubationData && (
              <button className={cn('assay-reagents__modal-btn')} onClick={handleIncubationDataClick}>
                <span className={cn('assay-reagents__badge')}>{incubationData.deviceText}</span>
                <span className={cn('assay-reagents__badge')}>{incubationData.duration}</span>
                <span className={cn('assay-reagents__badge')}>{incubationData.temperature}</span>
              </button>
            )}
            <Button
              onClick={handleIncubationDataClick}
              isEmptyStyles
              type="button"
              className={cn('assay-reagents__badge', 'assay-reagents__header-btn')}
            >
              <icons.RunDesignClockIcon className={cn('header__button-icon')} height={30} width={30} />{' '}
            </Button>
          </div>
        </RunDesignCard.Header>
        <RunDesignCard.Content onClick={handleCardContentClick} className={cn('content')}>
          {shouldDisplayAddInformation && (
            <AddInformation
              label={isIncubation ? 'Add treatments' : 'Add reagents'}
              className={cn('add-info', { 'add-info_incubation': isIncubation })}
            />
          )}
          {(!shouldDisplayAddInformation || isIncubation) && (
            <EditReagents
              isEdit={isEditMode}
              isIncubation={isIncubation}
              withWaves={withWaves}
              currentReagentListByLaneList={currentReagentListByLaneList}
              setCurrentReagentListByLaneList={setCurrentReagentListByLaneList}
              mediaSettingsByLaneMap={mediaSettingsByLaneMap}
              setMediaSettingsByLaneMap={setMediaSettingsByLaneMap}
            />
          )}
        </RunDesignCard.Content>
      </RunDesignCard>
    </ReagentListContext.Provider>
  );
};

export default ReagentsCard;
