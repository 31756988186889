import { MutableRefObject } from 'react';

import { arrToMapByKeys, capitalizeFirstLetter, isKeyOf, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { CagingSettingsItem, CCEType, LaneCagingSettings } from '@/graphql/API';

import {
  TCagingSettingNumberField,
  TRunDesignLane,
  TRunDesignLaneCagingSettings,
} from '@/store/slices/experimentRunDesign';

import { ADVANCED_SETTINGS, CAGE_SETTINGS_MAIN_SECTIONS, DEFAULT_GLOBAL_SETTINGS } from './constants';
import { TLaneCagingSettings } from './components/EditSettings/types';
import { validateCagingSettings } from './components/EditSettings/helpers';

export const formatCCETypeLabel = (cceType: CCEType) => {
  const parsedStr = cceType.replaceAll('_', ' ').toLowerCase();
  return capitalizeFirstLetter(parsedStr).replaceAll('cce', 'CCE');
};

export const getDefaultHeaderSections = (isSomeSubtractiveCCE: boolean) => {
  if (isSomeSubtractiveCCE) return CAGE_SETTINGS_MAIN_SECTIONS;

  return CAGE_SETTINGS_MAIN_SECTIONS.map((section) => {
    if (section.key === 'cellsToTarget') {
      const spliced = section.sections.toSpliced(3, 2);
      return {
        key: section.key,
        sections: spliced,
      };
    }
    return section;
  });
};

export const getHeaderSectionsForEditMode = (isSomeSubtractiveCCE: boolean, isViewMoreActive?: boolean) => {
  const defaultSections = getDefaultHeaderSections(isSomeSubtractiveCCE);
  const sections = defaultSections.toSpliced(defaultSections.length - 1, 1);

  if (isViewMoreActive) {
    sections.push({
      key: 'fullAdvanced',
      sections: [...ADVANCED_SETTINGS],
    });
  }

  sections.push({
    key: 'resetToDefault',
    sections: [
      {
        title: 'Reset to default',
        key: 'resetToDefault',
      },
    ],
  });

  return sections;
};

export const cceTypeOptions = Object.values(CCEType).map((value) => ({
  label: formatCCETypeLabel(value),
  value,
}));

export const prepareLanesCagingSettingsData = (
  newLaneCagingSettings: Nullable<TLaneCagingSettings>[],
  isSomeErrorRef: MutableRefObject<boolean>
) => {
  const preparedLanesData = newLaneCagingSettings.reduce((acc: TRunDesignLaneCagingSettings[], laneInfo) => {
    if (!laneInfo) {
      return acc;
    }

    laneInfo.errors = {};

    const { isValid, errors } = validateCagingSettings(laneInfo);
    if (!isValid) {
      isSomeErrorRef.current = true;
      laneInfo.errors = errors;
    }

    const { laneId, overrideSettings, cellToCage, magnification, cellToSubtract, cceType } = laneInfo;

    acc.push({
      laneId,
      magnification,
      cceType,
      cellToCage: cellToCage ?? null,
      cellToSubtract: cellToSubtract ?? null,
      overrideSettings: overrideSettings ? { ...overrideSettings } : null,
      __typename: 'LaneCagingSettings',
    });

    return acc;
  }, []);

  return preparedLanesData;
};

export const updateLaneSettingsWhenGlobalChanged = (
  currentSettings: Nullable<TLaneCagingSettings>[],
  fieldToUpdate: TCagingSettingNumberField,
  value: number
) =>
  currentSettings.map((settings) => {
    if (settings?.errors) {
      settings.errors = {};
    }

    if (isDefined(settings?.overrideSettings?.[fieldToUpdate])) {
      settings.overrideSettings[fieldToUpdate] = value;
      const { isValid, errors } = validateCagingSettings(settings);

      if (!isValid) {
        settings.errors = errors;
      }
    }

    return settings;
  });

export const getLanesCagingSettingsFromSaved = (
  globalCagingSettings: CagingSettingsItem,
  laneLetterMap: Record<string, TRunDesignLane>,
  lanesCagingSettings?: LaneCagingSettings[]
) => {
  const settingsByLaneMap = arrToMapByKeys(lanesCagingSettings ?? [], 'laneId');
  const settingsByLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
    if (!laneLetterMap[letter]) {
      return null;
    }
    const result: TLaneCagingSettings = {
      ...settingsByLaneMap[letter],
      overrideSettings: {
        ...globalCagingSettings,
        ...settingsByLaneMap[letter]?.overrideSettings,
      },
      laneId: letter,
      errors: {},
    };
    return result;
  });

  return settingsByLaneList;
};

export const getChangedLaneSettings = (
  overrideSettings?: Nullable<CagingSettingsItem>,
  globalSettings?: CagingSettingsItem
) => {
  const res = Object.keys(DEFAULT_GLOBAL_SETTINGS).reduce<Record<string, number>>((acc, key) => {
    const currentLaneSetting = isKeyOf(key, overrideSettings ?? {}) ? overrideSettings?.[key] : null;
    const currentGlobalSetting = isKeyOf(key, globalSettings ?? {}) ? globalSettings?.[key] : null;
    if (!isDefined(currentGlobalSetting) || !isDefined(currentLaneSetting)) return acc;

    if (currentGlobalSetting === currentLaneSetting) return acc;

    acc[key] = currentLaneSetting;

    return acc;
  }, {});

  return res;
};
