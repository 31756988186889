import { FC, memo } from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerCustomInput from '@/components/common/DatePickerCustomInput/DatePickerCustomInput';

import styles from './DateRange.module.scss';

const cn = classnames.bind(styles);

type TDateRangeProps = {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  onChangeStartDate: (date: Nullable<Date>) => void;
  onChangeEndDate: (date: Nullable<Date>) => void;
  disabled?: boolean;
};

const DateRange: FC<TDateRangeProps> = ({ startDate, endDate, onChangeStartDate, onChangeEndDate, disabled }) => (
  <div className={cn('filters')}>
    <span>
      <b>Date range:</b>
    </span>
    <span>From</span>
    <DatePicker
      selected={startDate}
      onChange={onChangeStartDate}
      className={cn('filters__input')}
      calendarClassName={cn('filters__calendar')}
      popperClassName={cn('filters__popper')}
      enableTabLoop={false}
      dateFormat="yyyy-MM-dd HH:mm"
      showPopperArrow={false}
      maxDate={endDate || undefined}
      showTimeInput
      shouldCloseOnSelect={false}
      customTimeInput={<DatePickerCustomInput />}
      popperPlacement="bottom-start"
      onFocus={(e) => e.target.blur()}
      disabled={disabled}
    />
    <span>To</span>
    <DatePicker
      selected={endDate}
      onChange={onChangeEndDate}
      className={cn('filters__input')}
      popperClassName={cn('filters__popper')}
      enableTabLoop={false}
      dateFormat="yyyy-MM-dd HH:mm"
      showPopperArrow={false}
      minDate={startDate || undefined}
      maxDate={endDate || undefined}
      showTimeInput
      shouldCloseOnSelect={false}
      customTimeInput={<DatePickerCustomInput />}
      popperPlacement="bottom-start"
      onFocus={(e) => e.target.blur()}
      disabled={disabled}
    />
  </div>
);

export default memo(DateRange);
