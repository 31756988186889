import { FC, MutableRefObject, useCallback, useMemo, useState, MouseEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import isEqual from 'lodash.isequal';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TCagingSettingNumberField,
} from '@/store/slices/experimentRunDesign';
import { isKeyOf } from '@/helpers';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import icons from '@/components/common/icons';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import styles from './GlobalSettings.module.scss';
import globalSettingsStyles from '../CageSettingsInfoModal/CageSettingsInfoModal.module.scss';

import { ADVANCED_SETTINGS, DEFAULT_GLOBAL_SETTINGS } from '../../constants';
import {
  getValidationInfo,
  prepareValidationConfigForAdvancedSettings,
  validateCagingSettings,
} from '../EditSettings/helpers';
import { updateLaneSettingsWhenGlobalChanged } from '../../helpers';
import SettingInput from '../EditSettings/components/SettingsInput/SettingsInput';

const cn = classnames.bind({ ...globalSettingsStyles, ...styles });

type TGlobalSettings = {
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  openTimelineSettingsModal: () => void;
};

const GlobalSettings: FC<TGlobalSettings> = ({ cardRef, openTimelineSettingsModal }) => {
  const appDispatch = useAppDispatch();

  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const [validationErrors, setValidationErrors] = useState({});
  const {
    newGlobalSettings,
    setNewGlobalSettings,
    newLaneCagingSettings,
    setNewLaneCagingSettings,
    resetLaneSettingsToGlobal,
  } = useCagingSettingsContext();

  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings);

  const isSomeGlobalSettingsChanged = useMemo(() => {
    if (!globalCagingSettings) return false;

    return !isEqual(
      {
        __typename: 'CagingSettingsItem',
        ...DEFAULT_GLOBAL_SETTINGS,
      },
      globalCagingSettings
    );
  }, [globalCagingSettings]);

  const editableSettingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const settingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && !setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      setNewGlobalSettings((prev) => {
        if (!prev) return null;
        const newSettings = structuredClone(prev);

        newSettings[field] = value;
        validateCagingSettings(undefined, newSettings);
        return newSettings;
      });

      setNewLaneCagingSettings((prev) => updateLaneSettingsWhenGlobalChanged(prev, field, value));
    },
    []
  );

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  const resetGlobalSettingsToDefault = useCallback(() => {
    setNewGlobalSettings((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        ...DEFAULT_GLOBAL_SETTINGS,
      };
    });

    resetLaneSettingsToGlobal({
      __typename: 'CagingSettingsItem',
      ...DEFAULT_GLOBAL_SETTINGS,
    });
  }, [globalCagingSettings, newGlobalSettings]);

  useEffect(() => {
    if (!newGlobalSettings) return;
    const { errors } = validateCagingSettings(undefined, newGlobalSettings);
    setValidationErrors(errors);
  }, [newGlobalSettings]);

  return (
    <div className={cn('global-settings')} ref={cardRef}>
      <div className={cn('global-settings__header')}>
        <span className={cn('global-settings__header-title')}>Review and edit global CCE settings as needed</span>
        <div className={cn('global-settings__header-buttons')}>
          <button
            type="button"
            onClick={openTimelineSettingsModal}
            className={cn('global-settings__button', 'global-settings__button_timeline')}
          >
            <icons.RunDesignClockIcon className={cn('global-settings__button-icon')} height={30} width={30} />{' '}
          </button>
          {isSomeGlobalSettingsChanged && isEditMode && (
            <button
              type="button"
              onClick={resetGlobalSettingsToDefault}
              className={cn('global-settings__button', 'global-settings__button_reset')}
            >
              <icons.ReloadIcon className={cn('modal__button-icon')} />
              <span className={cn('global-settings__button-substring')}>Reset to default</span>
            </button>
          )}
        </div>
      </div>
      <div
        role="presentation"
        onClick={handleCardContentClick}
        className={cn('container', { container_clickable: !isEditMode })}
      >
        <RunDesignTable
          tableData={[]}
          className={cn('table')}
          headerClassName={cn('table__header')}
          header={
            <RunDesignTable.Row>
              <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
              <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
              <RunDesignTable.Column />
            </RunDesignTable.Row>
          }
        >
          {editableSettingsList.map(({ key, title, icon, customdata = '' }) => {
            const isMixed =
              newGlobalSettings?.[key] === globalCagingSettings?.[key] &&
              newLaneCagingSettings?.some((settings) => {
                if (!newGlobalSettings?.[key] || !settings) return false;
                return settings?.overrideSettings?.[key] !== newGlobalSettings?.[key];
              });
            return (
              <RunDesignTable.Row key={key} className={cn('table__row')}>
                <RunDesignTable.Column className={cn('table__column')}>
                  <div className={cn('table__column-content')}>
                    {title} {customdata}
                  </div>
                  <div className={cn('table__column-content_light')}>
                    {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                  </div>
                </RunDesignTable.Column>

                <RunDesignTable.Column className={cn('table__column')}>
                  <SettingInput
                    onChangeNumberField={handleChangeNumberField}
                    isMixed={isMixed}
                    value={newGlobalSettings?.[key] ?? 0}
                    field={key}
                    errors={validationErrors}
                    settings={newGlobalSettings}
                    defaultValue={globalCagingSettings?.[key] ?? undefined}
                    isEditMode={isEditMode}
                    cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
                  />
                </RunDesignTable.Column>
                <RunDesignTable.Column className={cn('table__column', 'table__column_icon')}>
                  {icon}
                </RunDesignTable.Column>
              </RunDesignTable.Row>
            );
          })}
        </RunDesignTable>
        <div>
          <RunDesignTable
            tableData={[]}
            className={cn('table')}
            headerClassName={cn('table__header')}
            header={
              <RunDesignTable.Row>
                <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
                <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
                <RunDesignTable.Column />
              </RunDesignTable.Row>
            }
          >
            {settingsList.map(({ key, title, icon, customdata = '' }, index) => (
              <RunDesignTable.Row key={key} className={cn('table__row')}>
                <RunDesignTable.Column
                  className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
                >
                  <div className={cn('table__column-content')}>
                    {title} {customdata} *
                  </div>
                  <div className={cn('table__column-content_light')}>
                    {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                  </div>
                </RunDesignTable.Column>
                <RunDesignTable.Column
                  className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
                >
                  <span>{newGlobalSettings?.[key]}</span>
                </RunDesignTable.Column>
                <RunDesignTable.Column
                  className={cn(
                    'table__column',
                    { table__column_bordered: index === settingsList.length - 1 },
                    'table__column_icon'
                  )}
                >
                  {icon}
                </RunDesignTable.Column>
              </RunDesignTable.Row>
            ))}
          </RunDesignTable>
          <div className={cn('info')}>
            <span> *</span>
            <span className={cn('info__text')}>Default values for settings are displayed but cannot be edited.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
