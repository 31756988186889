// this is an auto generated file. This will be overwritten

import * as APITypes from './API';

type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const zipExperimentDataset = /* GraphQL */ `mutation ZipExperimentDataset($experimentId: String!) {
  zipExperimentDataset(experimentId: $experimentId) {
    url
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.ZipExperimentDatasetMutationVariables, APITypes.ZipExperimentDatasetMutation>;
export const processCageLevelAnalysis = /* GraphQL */ `mutation ProcessCageLevelAnalysis(
  $experimentId: String!
  $gate: [Gate]!
  $objectType: String!
) {
  processCageLevelAnalysis(
    experimentId: $experimentId
    gate: $gate
    objectType: $objectType
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<APITypes.ProcessCageLevelAnalysisMutationVariables, APITypes.ProcessCageLevelAnalysisMutation>;
export const publishExperimentEvent = /* GraphQL */ `mutation PublishExperimentEvent($event: ExperimentEventInput!) {
  publishExperimentEvent(event: $event) {
    organizationId
    experimentId
    type
    time
    payload
    __typename
  }
}
` as GeneratedMutation<APITypes.PublishExperimentEventMutationVariables, APITypes.PublishExperimentEventMutation>;
export const createPrimaryAnalysisGates = /* GraphQL */ `mutation CreatePrimaryAnalysisGates(
  $organizationId: String!
  $experimentId: String!
  $scanId: String
  $laneId: String
) {
  createPrimaryAnalysisGates(
    organizationId: $organizationId
    experimentId: $experimentId
    scanId: $scanId
    laneId: $laneId
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrimaryAnalysisGatesMutationVariables,
  APITypes.CreatePrimaryAnalysisGatesMutation
>;
export const createRunDesignFromTemplate =
  /* GraphQL */ `mutation CreateRunDesignFromTemplate($input: CreateRunDesignFromTemplateInput) {
  createRunDesignFromTemplate(input: $input) {
    organizationId
    id
    name
    description
    investigatorId
    projectId
    externalLinks
    organisms
    createdAt
    updatedAt
    publishedAt
    templateVersionId
    wizardStep
    flowcellType
    schema {
      __typename
    }
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateRunDesignFromTemplateMutationVariables,
    APITypes.CreateRunDesignFromTemplateMutation
  >;
export const updateRunDesign = /* GraphQL */ `mutation UpdateRunDesign($id: ID!, $input: UpdateRunDesignInput) {
  updateRunDesign(id: $id, input: $input) {
    success
    runDesign {
      organizationId
      id
      name
      description
      investigatorId
      projectId
      externalLinks
      organisms
      createdAt
      updatedAt
      publishedAt
      templateVersionId
      wizardStep
      flowcellType
      __typename
    }
    errors {
      message
      path
      code
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateRunDesignMutationVariables, APITypes.UpdateRunDesignMutation>;
export const publishRunDesign = /* GraphQL */ `mutation PublishRunDesign($id: ID!) {
  publishRunDesign(id: $id) {
    success
    runDesign {
      organizationId
      id
      name
      description
      investigatorId
      projectId
      externalLinks
      organisms
      createdAt
      updatedAt
      publishedAt
      templateVersionId
      wizardStep
      flowcellType
      __typename
    }
    errors {
      message
      path
      code
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.PublishRunDesignMutationVariables, APITypes.PublishRunDesignMutation>;
export const createRunDesignTemplate =
  /* GraphQL */ `mutation CreateRunDesignTemplate($input: CreateRunDesignTemplateInput) {
  createRunDesignTemplate(input: $input) {
    id
    name
    description
    authorId
    activeVersionId
    activeVersion {
      id
      templateId
      description
      authorId
      version
      createdAt
      updatedAt
      publishedAt
      deprecatedAt
      __typename
    }
    createdAt
    updatedAt
    deprecatedAt
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateRunDesignTemplateMutationVariables, APITypes.CreateRunDesignTemplateMutation>;
export const createRunDesignTemplateVersion = /* GraphQL */ `mutation CreateRunDesignTemplateVersion(
  $input: CreateRunDesignTemplateVersionInput
) {
  createRunDesignTemplateVersion(input: $input) {
    id
    templateId
    description
    authorId
    version
    createdAt
    updatedAt
    publishedAt
    deprecatedAt
    schema {
      __typename
    }
    tags {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRunDesignTemplateVersionMutationVariables,
  APITypes.CreateRunDesignTemplateVersionMutation
>;
export const publishRunDesignTemplateVersion = /* GraphQL */ `mutation PublishRunDesignTemplateVersion($id: ID!) {
  publishRunDesignTemplateVersion(id: $id) {
    success
    templateVersion {
      id
      templateId
      description
      authorId
      version
      createdAt
      updatedAt
      publishedAt
      deprecatedAt
      __typename
    }
    errors {
      code
      message
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishRunDesignTemplateVersionMutationVariables,
  APITypes.PublishRunDesignTemplateVersionMutation
>;
export const activateRunDesignTemplateVersion = /* GraphQL */ `mutation ActivateRunDesignTemplateVersion($id: ID!) {
  activateRunDesignTemplateVersion(id: $id) {
    success
    templateVersion {
      id
      templateId
      description
      authorId
      version
      createdAt
      updatedAt
      publishedAt
      deprecatedAt
      __typename
    }
    errors {
      code
      message
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivateRunDesignTemplateVersionMutationVariables,
  APITypes.ActivateRunDesignTemplateVersionMutation
>;
export const deleteRunDesign = /* GraphQL */ `mutation DeleteRunDesign($id: ID!) {
  deleteRunDesign(id: $id) {
    success
    runDesignId
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteRunDesignMutationVariables, APITypes.DeleteRunDesignMutation>;
export const enqueueJobRun = /* GraphQL */ `mutation EnqueueJobRun($input: EnqueueJobRunInput!) {
  enqueueJobRun(input: $input) {
    organizationId
    id
    name
    jobDefinition {
      id
      revision
      __typename
    }
    containerOverrides {
      command
      __typename
    }
    status
    createdAt
    updatedAt
    startedAt
    endedAt
    timeoutInSeconds
    userId
    cancelReason
    logUrl
    tags {
      key
      value
      __typename
    }
    dependsOn
    rerunOf
    __typename
  }
}
` as GeneratedMutation<APITypes.EnqueueJobRunMutationVariables, APITypes.EnqueueJobRunMutation>;
export const cancelJobRun = /* GraphQL */ `mutation CancelJobRun($id: ID!, $reason: String!) {
  cancelJobRun(id: $id, reason: $reason) {
    organizationId
    id
    name
    jobDefinition {
      id
      revision
      __typename
    }
    containerOverrides {
      command
      __typename
    }
    status
    createdAt
    updatedAt
    startedAt
    endedAt
    timeoutInSeconds
    userId
    cancelReason
    logUrl
    tags {
      key
      value
      __typename
    }
    dependsOn
    rerunOf
    __typename
  }
}
` as GeneratedMutation<APITypes.CancelJobRunMutationVariables, APITypes.CancelJobRunMutation>;
export const terminateJobRun = /* GraphQL */ `mutation TerminateJobRun($id: ID!, $reason: String!) {
  terminateJobRun(id: $id, reason: $reason) {
    organizationId
    id
    name
    jobDefinition {
      id
      revision
      __typename
    }
    containerOverrides {
      command
      __typename
    }
    status
    createdAt
    updatedAt
    startedAt
    endedAt
    timeoutInSeconds
    userId
    cancelReason
    logUrl
    tags {
      key
      value
      __typename
    }
    dependsOn
    rerunOf
    __typename
  }
}
` as GeneratedMutation<APITypes.TerminateJobRunMutationVariables, APITypes.TerminateJobRunMutation>;
export const rerunJobRun = /* GraphQL */ `mutation RerunJobRun($id: ID!) {
  rerunJobRun(id: $id) {
    organizationId
    id
    name
    jobDefinition {
      id
      revision
      __typename
    }
    containerOverrides {
      command
      __typename
    }
    status
    createdAt
    updatedAt
    startedAt
    endedAt
    timeoutInSeconds
    userId
    cancelReason
    logUrl
    tags {
      key
      value
      __typename
    }
    dependsOn
    rerunOf
    __typename
  }
}
` as GeneratedMutation<APITypes.RerunJobRunMutationVariables, APITypes.RerunJobRunMutation>;
export const createJobDefinition =
  /* GraphQL */ `mutation CreateJobDefinition($jobConfig: JobDefinitionInput!, $id: ID) {
  createJobDefinition(jobConfig: $jobConfig, id: $id) {
    id
    name
    createdAt
    updatedAt
    revisions {
      number
      createdAt
      executor
      jobRoleArn
      timeoutInSeconds
      ephemeralStorageInGiB
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateJobDefinitionMutationVariables, APITypes.CreateJobDefinitionMutation>;
export const publishJobRunStatusEvent =
  /* GraphQL */ `mutation PublishJobRunStatusEvent($event: JobRunStatusChangedEventInput!) {
  publishJobRunStatusEvent(event: $event) {
    organizationId
    id
    jobRunId
    time
    payload {
      status
      logUrl
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.PublishJobRunStatusEventMutationVariables, APITypes.PublishJobRunStatusEventMutation>;
export const createAuditLog = /* GraphQL */ `mutation CreateAuditLog($input: CreateAuditLogInput!) {
  createAuditLog(input: $input) {
    id
    action
    description
    organizationId
    service
    scope
    correlationId
    occurredAt
    registeredAt
    actor {
      type
      id
      name
      metadata
      __typename
    }
    metadata
    details
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateAuditLogMutationVariables, APITypes.CreateAuditLogMutation>;

export const generateReagentManifest =
  /* GraphQL */ `mutation GenerateReagentManifest($generateReagentManifestId: ID!) {
  generateReagentManifest(id: $generateReagentManifestId) {
    downloadUrl
  }
}
` as GeneratedMutation<APITypes.GenerateReagentManifestMutationVariables, APITypes.GenerateReagentManifestMutation>;
