import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import * as queries from '@/graphql/queries';

import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';

import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';
import styles from './LiveSensors.module.scss';

import InstrumentCard from '../InstrumentCard';
import SensorListItem from '../SensorListItem';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

const LiveSensors: FC = () => {
  const instrumentId = useParamsInstrumentId();

  const {
    data,
    loading: isInstrumentTelemetryLoading,
    error: isInstrumentTelemetryError,
  } = useQuery(queries.telemetryByInstrument, {
    variables: {
      instrumentId,
    },
  });

  const isEmptySensorsData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !data?.telemetryByInstrument?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, data?.telemetryByInstrument]
  );

  return (
    <InstrumentCard className={cn('content-block')}>
      <InstrumentCard.Header title="Live sensor readings">
        <InstrumentCard.HeaderLeftActions>
          <span>{format(new Date(), 'yyyy-MM-dd')}</span>
        </InstrumentCard.HeaderLeftActions>
      </InstrumentCard.Header>
      <InstrumentCard.Content
        isLoading={isInstrumentTelemetryLoading}
        isNoData={!!(isEmptySensorsData || isInstrumentTelemetryError)}
        noDataMessage={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
      >
        <div className={cn('list-block')}>
          <div className={cn('list-block__list')}>
            {data?.telemetryByInstrument?.map((telemetry: TTelemetryByInstrument) => (
              <SensorListItem key={telemetry.name} telemetrySensor={telemetry} />
            ))}
          </div>
        </div>
      </InstrumentCard.Content>
    </InstrumentCard>
  );
};

export default memo(LiveSensors);
