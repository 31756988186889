import { Dispatch, FC, FormEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Textarea from '@/components/common/Textarea';
import TextInput from '@/components/common/TextInput';
import { antnAPI } from '@/store/services/annotation';
import { includes } from '@/helpers/enum';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import RadioButton from '@/components/common/RadioButton.tsx';
import { isKeyOf, MDASH } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import ChannelSelector from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector';
import StaticChannel from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector/StaticChannel';
import { getDefaultWavesConfig } from '@/helpers/waveLengths';
import icons from '@/components/common/icons';
import styles from './EditInformation.module.scss';

const cn = classNames.bind(styles);

type TEditInformationProps = {
  shouldFormSubmitTrigger: boolean;
  onDataGet: (_data: TReturnTransformedTypes) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  fieldList?: TReturnTransformedTypes;
  contentType: 'new' | 'edit' | 'info' | 'custom';
  isCustom: boolean;
};

const EditInformation: FC<TEditInformationProps> = ({
  shouldFormSubmitTrigger,
  onDataGet,
  setIsLoading,
  fieldList,
  contentType,
  isCustom,
}) => {
  const { config } = useReagentModalContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isInitialRender, setIsInitialRender] = useState(true); // submit on init disabler
  const [postAnnotation] = antnAPI.usePostAnnotationsMutation();
  const [patchAnnotations] = antnAPI.usePatchAnnotationsMutation();

  useEffect(() => {
    if (!formRef.current || isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    formRef.current.requestSubmit();
  }, [shouldFormSubmitTrigger]);

  // set form fields data
  useEffect(() => {
    if (!fieldList) {
      return;
    }

    if (contentType !== 'edit') {
      return;
    }

    const formFieldList = formRef.current
      ? ([...formRef.current.elements] as (HTMLInputElement | HTMLTextAreaElement)[])
      : [];
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

    formFieldList.forEach((formField) => {
      if (isKeyOf(formField.name, fieldList)) {
        if (
          isDefined(nativeInputValueSetter) &&
          includes(['excitationWavelength', 'detectionWavelength'], formField.name)
        ) {
          nativeInputValueSetter.call(formField, fieldList[formField.name]);

          const event = new Event('input', { bubbles: true });
          formField.dispatchEvent(event);
          return;
        }

        const newValue = fieldList[formField.name];
        if (!isDefined(newValue)) {
          return;
        }

        if (formField instanceof HTMLInputElement && formField.type === 'radio' && formField.value === newValue) {
          formField.checked = true;
          return;
        }

        if (typeof newValue === 'string') {
          formField.value = newValue;
        }

        if (Array.isArray(newValue) && newValue.length) {
          const [newSingleValue] = newValue;
          formField.value = newSingleValue;
        }
      }
    });
  }, [fieldList, contentType]);

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async (ev) => {
    ev.preventDefault();
    const patchAnnotationMethod = (data: Record<string, unknown>) => {
      if (!isDefined(fieldList?.id)) {
        return {
          error: 'Annotation id not provided',
        };
      }

      return patchAnnotations({ id: fieldList.id, data });
    };

    const sendDataMethod = includes(['custom', 'new'], contentType) ? postAnnotation : patchAnnotationMethod;
    const formData = new FormData(ev.target as HTMLFormElement);
    const annotationData: Record<string, string | string[] | number[] | Record<string, unknown>> = {
      type: config.type,
      metadata: {},
    };

    for (const [key, value] of formData.entries()) {
      if (typeof value === 'string' && value !== '') {
        if (includes(['excitationWavelength', 'detectionWavelength'], key)) {
          annotationData[key] = value.split(', ').map((wave) => Number(wave));
        } else if (includes(['geneId', 'catalogNumbers'], key)) {
          annotationData[key] = [value];
        } else {
          annotationData[key] = value;
        }
      }
    }

    try {
      setIsLoading(true);
      const res = await sendDataMethod(annotationData);
      if ('error' in res) {
        showErrorToast(getErrorMessage(res.error));
        return;
      }

      onDataGet(res.data);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className={cn('custom-information')} onSubmit={handleFormSubmit} ref={formRef}>
      <ul className={cn('custom-information__group-list')}>
        {config.custom?.fieldList.map((fieldData, index) => (
          <li className={cn('custom-information__group-row')} key={index}>
            <ul className={cn('custom-information__list')}>
              {fieldData.map(({ isRequired, label, name, type, placeholder, pattern, ...field }) => {
                const fieldByName = fieldList?.[name as keyof typeof fieldList];
                const fieldByNameData =
                  isDefined(fieldByName) && Array.isArray(fieldByName) ? fieldByName[0] : fieldByName;
                const defaultWaves = getDefaultWavesConfig();
                const waveData = name === 'detectionWavelength' ? defaultWaves.em : defaultWaves.ex;
                let opticalSettings: Nullable<{
                  name: string;
                  hex: string;
                  symbol: string;
                }> = {
                  name: waveData.uiLabel,
                  hex: waveData.color,
                  symbol: waveData.label,
                };

                if (isDefined(fieldList) && 'opticalDisplay' in fieldList && isDefined(fieldList.opticalDisplay)) {
                  if (name === 'detectionWavelength' && isDefined(fieldList.opticalDisplay.detection)) {
                    opticalSettings = fieldList?.opticalDisplay.detection;
                  }

                  if (name !== 'detectionWavelength' && isDefined(fieldList.opticalDisplay.excitation)) {
                    opticalSettings = fieldList?.opticalDisplay.excitation;
                  }
                }

                return (
                  <li className={cn('custom-information__row')} key={name}>
                    {contentType === 'info' && (
                      <div className={cn('custom-information__label')}>
                        <div className={cn('custom-information__text')}>
                          {typeof label === 'function' ? label(isCustom) : label}
                        </div>
                        {includes(['excitationWavelength', 'detectionWavelength'], name) && (
                          <StaticChannel optical={opticalSettings} />
                        )}

                        {!includes(['excitationWavelength', 'detectionWavelength'], name) && (
                          <div className={cn('custom-information__info')}>
                            {includes(['url'], type) && isDefined(fieldByNameData) ? (
                              <a
                                href={String(fieldByNameData)}
                                className={cn('custom-information__link')}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {String(fieldByNameData)}
                                <icons.LinkIcon className={cn('custom-information__link-icon')} />
                              </a>
                            ) : (
                              fieldByNameData ?? MDASH
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {includes(['edit', 'new', 'custom'], contentType) && (
                      <label className={cn('custom-information__label')}>
                        <div className={cn('custom-information__text')}>
                          {typeof label === 'function' ? label(isCustom) : label}
                          {isRequired && <span className={cn('custom-information__text-required')}>*</span>}
                        </div>
                        {type === 'textarea' && (
                          <Textarea
                            className={cn('custom-information__textarea')}
                            placeholder={typeof placeholder === 'function' ? placeholder(isCustom) : placeholder}
                            name={name}
                          />
                        )}
                        {includes(['url', 'number', 'text'], type) && (
                          <TextInput
                            className={cn('custom-information__input-wrap')}
                            inputClassName={cn('custom-information__input')}
                            placeholder={typeof placeholder === 'function' ? placeholder(isCustom) : placeholder}
                            name={name}
                            required={isRequired}
                            pattern={pattern}
                            type={type}
                          />
                        )}
                        {type === 'radio' && isKeyOf('options', field) && isDefined(field.options) && (
                          <div className={cn('custom-information__radio-list')}>
                            {field.options.map((option) => (
                              <RadioButton
                                label={option.label}
                                theme="light"
                                className={cn('custom-information__radio')}
                                key={option.value}
                                name={name}
                                value={option.value}
                              />
                            ))}
                          </div>
                        )}
                        {type === 'channel-selector' && isKeyOf('category', field) && isDefined(field.category) && (
                          <ChannelSelector
                            category={field.category}
                            name={name}
                            required={!!isRequired}
                            className={cn('custom-information__input-wrap')}
                          />
                        )}
                      </label>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </form>
  );
};

export default EditInformation;
