import {
  Dispatch,
  FC,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton from 'react-loading-skeleton';
import { min as getMinDate, max as getMaxDate } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '@/hooks';
import usePlotProxy from '@/hooks/usePlotProxy';

import { handlePlotlyResize } from '@/helpers/plotly';
import { arrToMapByKeys, capitalizeFirstLetter, toFixed } from '@/helpers';
import { getMinMax } from '@/helpers/arrays';
import { getAxisGap } from '@/helpers/axisScaleHelper/helpers';

import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';

import { formatDateTime } from '@/pages/Home/helpers';

import '@/styles/datepicker.scss';

import styles from './SensorByTelemetryTimeline.module.scss';
import { getInstrumentChartDefaultLayout } from './helpers';

const cn = classnames.bind(styles);

type TTelemetryModalProps = {
  selectedSensor: TTelemetryByInstrument;
  isPlotReadyToDisplay: boolean;
  setIsPlotReadyToDisplay: Dispatch<SetStateAction<boolean>>;
  className?: string;
  data: TTelemetryBySensorFromServer[];
  isLoading: boolean;
  isError: boolean;
  onZoomChange: (minXRange: Nullable<Date>, maxXRange: Nullable<Date>) => void;
  prevDateValuesRef?: MutableRefObject<Nullable<[Date, Date]>>;
};

const SensorByTelemetryTimeline: FC<TTelemetryModalProps> = ({
  selectedSensor,
  className,
  isPlotReadyToDisplay,
  setIsPlotReadyToDisplay,
  data,
  isLoading,
  isError,
  onZoomChange,
  prevDateValuesRef,
}) => {
  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const [isReadyForFirstDraw, setIsReadyForFirstDraw] = useState(false);
  const [searchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? '';

  const isEmptyData = useMemo(() => !isLoading && !data?.length, [isLoading, data?.length]);
  const debouncedIsEmptyData = useDebounce(isEmptyData);
  const debouncedIsPlotReadyToDisplay = useDebounce(isPlotReadyToDisplay);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
      setIsReadyForFirstDraw(false);
    };
  }, []);

  const xValues = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];
    return sensorData.map((sensor) => formatDateTime(sensor?.time, 'yyyy-MM-dd HH:mm'));
  }, [data]);

  const yValues = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];

    return sensorData.map((sensor) => {
      if (sensor?.value && sensor?.type === 'string') {
        return capitalizeFirstLetter(String(sensor.value).toLowerCase() ?? '').replace('_', ' ');
      }

      return toFixed(Number(sensor?.value));
    });
  }, [data]);

  const isStringYAxis = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];

    return sensorData?.every((sensor) => sensor?.type === 'string');
  }, [data]);

  const xAxisRange = useMemo((): Nullable<[Date, Date]> => {
    if (isLoading || isError) return null;

    const dateObjects = xValues.map((x) => (x ? new Date(x) : new Date()));
    const minDate = getMinDate(dateObjects);
    const maxDate = getMaxDate(dateObjects);
    return [minDate, maxDate];
  }, [isLoading, yValues, data]);

  const yAxisRange = useMemo((): Nullable<[number, number]> => {
    if (isLoading || isError) return null;

    if (isStringYAxis) {
      const mapByKey = arrToMapByKeys(data ?? [], 'value');
      // consider all unique string values when forming a range
      const maxRange = Object.keys(mapByKey).length - 1;
      return [-0.1, maxRange + 0.1];
    }
    const { min, max } = getMinMax(yValues as number[]);
    const valDiff = max - min;
    const gap = getAxisGap(valDiff);

    return [min - gap, max + gap];
  }, [isStringYAxis, yValues, data, isLoading, isError]);

  const yAxisSuffix = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];
    if (!sensorData.length) return '';

    return ` ${sensorData[0].postfix}`;
  }, [data]);

  const fileName = useMemo(() => {
    const titleArr = [];

    if (instrumentName) {
      titleArr.push(instrumentName);
    }
    const sensorName = selectedSensor.name;

    if (sensorName) {
      titleArr.push(sensorName);
    }
    titleArr.push('timeline');

    return titleArr.join('-');
  }, [instrumentName, selectedSensor]);

  const postfixString = selectedSensor.postfix ? `(${selectedSensor.postfix})` : '';

  useEffect(() => {
    const newIsReadyForFirstDraw = !isError && !isLoading;
    setIsReadyForFirstDraw(newIsReadyForFirstDraw);
  }, [isError, isLoading]);

  useEffect(() => {
    if (!graphRef.current) return;

    setIsPlotReadyToDisplay(false);

    if (isEmptyData) {
      plotlyProxy.purge();
      setIsPlotReadyToDisplay(true);

      return;
    }

    const trace = {
      x: xValues,
      y: yValues,
      mode: 'lines+markers',
      type: 'scatter',
      line: {
        color: '#56e5f1',
        width: 2,
        shape: isStringYAxis ? 'hv' : 'linear',
      },
    };

    const layout = getInstrumentChartDefaultLayout({
      isStringYAxis,
      selectedSensor,
      yAxisSuffix,
      xAxisRange,
      yAxisRange,
    });

    plotlyProxy.react(
      [trace],
      layout,
      {
        // TODO: Enable zoom via scroll after fixing zoom jumps along the x-axis
        scrollZoom: false,
        displayModeBar: false,
        responsive: true,
      },
      () => setIsPlotReadyToDisplay(true)
    );
  }, [
    graphRef.current,
    xValues,
    yValues,
    isLoading,
    isEmptyData,
    isStringYAxis,
    xAxisRange,
    yAxisRange,
    selectedSensor.label,
    selectedSensor.postfix,
    isReadyForFirstDraw,
  ]);

  const handlePlotZoomed = useCallback((eventData: TPlotRelayoutEvent) => {
    const eventDataKeys = Object.keys(eventData);
    const isRangeEvent = eventDataKeys.length && eventDataKeys.every((key) => key.includes('range'));

    if (!isRangeEvent) return;
    const isZoomRangeEvent = eventDataKeys?.[0] === 'xaxis.range[0]';
    const isTimelineRangeEvent = eventDataKeys?.[0] === 'xaxis.range';

    let minX = null;
    let maxX = null;

    if (isZoomRangeEvent) {
      minX = eventData['xaxis.range[0]'] ? new Date(eventData['xaxis.range[0]']) : null;
      maxX = eventData['xaxis.range[1]'] ? new Date(eventData['xaxis.range[1]']) : null;
    } else if (isTimelineRangeEvent) {
      const newRangeData = eventData['xaxis.range'];

      minX = newRangeData?.[0] ? new Date(newRangeData[0]) : null;
      maxX = newRangeData?.[0] ? new Date(newRangeData[1]) : null;
    }

    if (!minX || !maxX) return;

    onZoomChange(minX, maxX);
  }, []);

  const resetRangeToDefault = useCallback(() => {
    if (!prevDateValuesRef?.current) return;
    onZoomChange(prevDateValuesRef.current[0], prevDateValuesRef.current[1]);
  }, [prevDateValuesRef?.current]);

  useEffect(() => {
    const graphDiv = graphRef.current;

    if (!graphRef?.current?.data?.[0]) return;
    graphRef.current.on('plotly_relayout', (eventData: TPlotRelayoutEvent) => handlePlotZoomed(eventData)); // event for zoom via selection
    graphRef.current.on('plotly_doubleclick', resetRangeToDefault);

    return () => {
      if (!graphDiv?.removeAllListeners) {
        return;
      }

      graphDiv?.removeAllListeners('plotly_relayouting');
      graphDiv?.removeAllListeners('plotly_relayout');
      graphDiv.removeAllListeners('plotly_doubleclick');
    };
  }, [graphRef.current?.data, handlePlotZoomed]);

  return (
    <div className={cn('timeline', className)}>
      <div className={cn('timeline__header')}>
        <div className={cn('timeline__header-titles')}>
          <span className={cn('timeline__header-title')}>{selectedSensor.label}</span>
          <DownloadChartButton
            graphRef={graphRef}
            fileName={fileName}
            xAxisLabel="Time"
            yAxisLabel={`${selectedSensor.label} ${postfixString}`}
            className={cn('download-btn')}
          />
        </div>
      </div>

      <div className={cn('timeline__content')}>
        {(isLoading || !debouncedIsPlotReadyToDisplay) && <Skeleton className={cn('skeleton')} />}
        <div ref={graphRef} id="sensor-by-telemetry-chart" className={cn('timeline__chart')}>
          {!isLoading && debouncedIsEmptyData && (
            <NoDataFound
              className={cn('no-data')}
              alignment="center"
              size="normal"
              textData={
                isError ? (
                  'Something went wrong'
                ) : (
                  <span>
                    No Data <br /> Try to change the date range
                  </span>
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SensorByTelemetryTimeline);
