import { LAYOUT_CONFIG } from '@/pages/Dataset/constants';
import { DEFAULT_TITLE_LAYOUT } from '@/components/charts/DownloadChartButton/prepareLayouts';

export const getInstrumentChartDefaultLayout = ({
  isStringYAxis,
  selectedSensor,
  yAxisSuffix,
  xAxisRange,
  yAxisRange,
}: {
  isStringYAxis: boolean;
  selectedSensor: TTelemetryByInstrument;
  yAxisSuffix: string;
  xAxisRange: Nullable<[Date, Date]>;
  yAxisRange: Nullable<[number, number]>;
}) => {
  const postfixString = selectedSensor.postfix ? `(${selectedSensor.postfix})` : '';

  return {
    ...LAYOUT_CONFIG(),
    yaxis: {
      ...LAYOUT_CONFIG().yaxis,
      griddash: 'dot',
      showgrid: !isStringYAxis,
      color: '#000000',
      ticksuffix: yAxisSuffix,
      fixedrange: true,
      autorange: true,
      range: yAxisRange,
      title: {
        ...LAYOUT_CONFIG().yaxis.title,
        text: `${selectedSensor.label} ${postfixString}`,
        standoff: 20,
        font: {
          ...DEFAULT_TITLE_LAYOUT,
          size: 17,
        },
      },
      automargin: true,
    },
    xaxis: {
      ...LAYOUT_CONFIG().xaxis,
      color: '#000000',
      nticks: 5,
      type: 'date',
      title: {
        ...LAYOUT_CONFIG().xaxis.title,
        text: 'Time',
        standoff: 20,
        font: {
          ...DEFAULT_TITLE_LAYOUT,
          size: 17,
        },
      },
      autorange: true,
      automargin: true,
      range: xAxisRange,
      rangeslider: {
        range: xAxisRange,
        thickness: 0.1,
      },
      // https://plotly.com/javascript/tick-formatting/#tickformatstops-to-customize-for-different-zoom-levels
      tickformatstops: [
        {
          dtickrange: [null, 60000],
          value: '%Y-%m-%d %H:%M:%S',
        },
        {
          dtickrange: [60000, 3600000],
          value: '%Y-%m-%d %H:%M',
        },
        {
          dtickrange: [3600000, 86400000],
          value: '%y-%m-%d %H:%M',
        },
        {
          dtickrange: [86400000, 604800000],
          value: '%Y-%m-%d',
        },
        {
          dtickrange: [604800000, 'M1'],
          value: '%Y-%m-%d',
        },
        {
          dtickrange: ['M1', 'M12'],
          value: '%b %Y',
        },
        {
          dtickrange: ['M12', null],
          value: '%Y',
        },
      ],
    },
    margin: {
      l: isStringYAxis ? 90 : 70,
      r: 20,
      t: 0,
      b: 0,
      pad: 0,
    },
    dragmode: true,
  };
};
