import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { FC } from 'react';
import { TCustomField } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/EditInformation/types';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';

export type TConfigReducerState = {
  type: typeof EAnnotationType | string;
  title: string;
  description: string;

  current?: Nullable<{ id: string; name: string }>;
  search: {
    placeholder: string;
    getData: (value: string, filters: Record<string, string>) => Promise<void | TReturnTransformedTypes[]>;
    result?: {
      headingRenderer?: FC;
      rowRenderer?: FC<{
        result: TReturnTransformedTypes;
      }>;
      cssGridLayout: string;
      informationList: string[];
    };
  };
  filter: Nullable<{
    hook: () => {
      filterState: Record<string, string>;
      updateFactory: (field: string) => (value: string) => void;
      cleanFilters: () => void;
      cssGridLayout: string;
    };
    renderer?: FC<{
      filterState: Record<string, string>;
      updateFactory: (field: string) => (value: string) => void;
      data?: TReturnTransformedTypes[];
      disabled?: boolean;
    }>;
  }>;
  custom: Nullable<{
    title: string;
    fieldList: TCustomField[][];
  }>;

  annotationTypeText: string;
  onSelect: (...args: any[]) => void;
};

export const EConfigActionList = {
  setConfig: 'config/set',
  cleanCurrent: 'config/clean-current',
} as const;

type TSetConfigAction = {
  type: typeof EConfigActionList.setConfig;
  payload: TConfigReducerState;
};

type TCleanCurrentAction = {
  type: typeof EConfigActionList.cleanCurrent;
};

export type TConfigReducerActionList = TSetConfigAction | TCleanCurrentAction;

export const configReducer = (state: TConfigReducerState, action: TConfigReducerActionList) => {
  if (action.type === EConfigActionList.setConfig) {
    return action.payload;
  }

  if (action.type === EConfigActionList.cleanCurrent) {
    return { ...state, current: null };
  }

  return state;
};

export const initialConfig = {
  type: EAnnotationType.reagent,
  title: '',
  description: '',
  current: null,
  search: {
    placeholder: '',
    getData: () => Promise.resolve([]),
  },
  filter: {
    hook: () => ({
      filterState: {},
      updateFactory: () => () => null,
      cleanFilters: () => null,
      cssGridLayout: '',
    }),
  },
  custom: null,
  annotationTypeText: '',
  onSelect: () => null,
};
