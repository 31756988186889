import { ComponentType } from 'react';
import { CagingSettingsContextProvider } from '@/hooks/runDesign/useCagingSettingsContext';

export function withRunDesignCagingSettingsContext<P extends object>(WrappedComponent: ComponentType<P>) {
  return function ComponentWithRunDesignCagingSettingsContext(props: P) {
    return (
      <CagingSettingsContextProvider>
        <WrappedComponent {...props} />
      </CagingSettingsContextProvider>
    );
  };
}
