import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CheckboxInput from '@/components/common/CheckboxInput';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { DEFAULT_SAMPLE_PREFIX } from '@/helpers';

type TSampleNamesSwitch = {
  className?: string;
  disabled?: boolean;
};

const SampleNamesSwitch: FC<TSampleNamesSwitch> = ({ className, disabled }) => {
  const appDispatch = useAppDispatch();
  const isChecked = useSelector(experimentRunDesignSelectors.selectIsAutoGenerateSampleNames);
  const lanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);
  const handleChangeStatus = () => {
    appDispatch(experimentRunDesignActions.toggleAutoGenerateSampleNameSwitch());
  };

  useEffect(() => {
    if (!lanes) return;
    const isSomeSampleNameWasGenerated = !!lanes?.some((lane) => lane.sample === `${DEFAULT_SAMPLE_PREFIX}-${lane.id}`);
    appDispatch(experimentRunDesignActions.setIsAutoGenerateSampleNameSwitch(isSomeSampleNameWasGenerated));
  }, [lanes]);

  return (
    <CheckboxInput
      checked={isChecked}
      onChange={handleChangeStatus}
      isSwitch
      theme="run-design"
      label="Auto-generate sample names"
      className={className}
      disabled={disabled}
    />
  );
};

export default SampleNamesSwitch;
