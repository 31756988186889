import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { CellFunction, CellIndex, CellType, Lane, Reagent } from '@/graphql/API';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignCellType,
  TRunDesignConsumable,
  TRunDesignConsumableToUse,
  TRunDesignLane,
} from '@/store/slices/experimentRunDesign';

import { LANE_LETTER_NAME_LIST } from '@/helpers';
import { RunDesignContext } from '@/pages/experiment-run-design/context';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableHeader from '@/components/runDesign/ConsumableComponent/ConsumableHeader';

import { isDefined } from '@/helpers/typeGuards';

import EditCellTypeName from './components/EditCellTypeName';
import EditCellTypeFunction from './components/EditCellTypeFunction';
import EditCellTypePreLabeling from './components/EditCellTypePreLabeling';

import styles from './EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeProps = {
  runDesignCardIndex: number;
  showFunctionBlock?: boolean;
  sampleTitle: string;
};

const EditCellType: FC<TEditCellTypeProps> = ({ runDesignCardIndex, showFunctionBlock, sampleTitle }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const [editableLaneList, setEditableLaneList] = useState<Partial<TRunDesignLane>[]>([]);

  const isSomeCellNameDefined = useMemo(
    () =>
      editableLaneList?.some(
        (lane) =>
          !!lane.cellTypes?.find((cellType, index) => index === runDesignCardIndex && !!cellType?.cellIndex?.name)
      ) ?? false,
    [editableLaneList]
  );

  const updateCellType = (
    laneIndex: number,
    newCellTypePart: Nullable<
      DeepPartial<Omit<CellType, 'preLabelings'> & { preLabelings: Nullable<Nullable<TRunDesignConsumableToUse>[]> }>
    >
  ) => {
    setEditableLaneList((prev) => {
      const newLaneList = structuredClone(prev);
      const lane = newLaneList[laneIndex];

      if (lane) {
        if (!lane.cellTypes || newCellTypePart === null) {
          lane.cellTypes = [];
        }
        const cellType = lane.cellTypes[runDesignCardIndex];
        const currentCellIndex = cellType?.cellIndex ?? null;

        const updatedCellIndex = newCellTypePart?.cellIndex
          ? {
              ...(currentCellIndex ?? {}),
              ...newCellTypePart.cellIndex,
            }
          : currentCellIndex;

        const newCellType: TRunDesignCellType = cellType
          ? { ...cellType, ...newCellTypePart, cellIndex: updatedCellIndex }
          : { __typename: 'CellType', name: '', function: CellFunction.EFFECTOR, ...newCellTypePart };

        lane.cellTypes[runDesignCardIndex] = newCellType;
        newLaneList[laneIndex] = lane;
      }
      return newLaneList;
    });
  };

  const tableData = useMemo(
    () => editableLaneList.map((lane) => lane.cellTypes?.[runDesignCardIndex] ?? null),
    [runDesignCardIndex, editableLaneList]
  );

  const updateFromTableData = useCallback(
    (newTableData: (TRunDesignCellType | null)[]) => {
      setEditableLaneList((prev) => {
        const newLaneList = structuredClone(prev);
        newLaneList.forEach((lane, laneIndex) => {
          if (!newTableData[laneIndex]) {
            return lane;
          }
          if (!lane.cellTypes) {
            lane.cellTypes = [];
          }
          lane.cellTypes[runDesignCardIndex] = newTableData[laneIndex];
        });
        return newLaneList;
      });
    },
    [runDesignCardIndex, editableLaneList]
  );

  const handleNameChange = (laneIndex: number, data: Nullable<{ name: string; id: string }>) => {
    if (!data) {
      setEditableLaneList((prev) => {
        const newLaneList = structuredClone(prev);
        const lane = newLaneList[laneIndex];

        if (lane && lane.cellTypes) {
          lane.cellTypes[runDesignCardIndex] = null;

          newLaneList[laneIndex] = lane;
        }
        return newLaneList;
      });

      return;
    }

    updateCellType(laneIndex, { cellIndex: { __typename: 'CellIndex', id: data.id, name: data.name } });
  };

  const handleFunctionChange = (laneIndex: number, newFunction: CellFunction) => {
    updateCellType(laneIndex, { function: newFunction });
  };

  const handlePreLabelingChange =
    (laneIndex: number, placementIndex: number) => (newConsumable: Nullable<TRunDesignConsumable>) => {
      const currentPreLabelingList = editableLaneList?.[laneIndex]?.cellTypes?.[runDesignCardIndex]?.preLabelings ?? [];

      if (isReagentConsumable(newConsumable)) {
        currentPreLabelingList.splice(placementIndex, 1, {
          __typename: 'ConsumableToUse',
          consumable: newConsumable,
        });
      } else if (placementIndex === currentPreLabelingList.length - 1) {
        currentPreLabelingList.splice(placementIndex, 1);
      } else {
        currentPreLabelingList.splice(placementIndex, 1, null);
      }

      if (!currentPreLabelingList.length) {
        updateCellType(laneIndex, {
          preLabelings: null,
        });
        return;
      }

      updateCellType(laneIndex, {
        preLabelings: currentPreLabelingList,
      });
    };

  useEffect(() => {
    const laneList = currentEditFields.schema?.lanes ?? [];
    const initLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
      const letterLane = laneList.find((lane) => lane.id === letter);
      if (letterLane) {
        return structuredClone(letterLane);
      }
      return { id: letter };
    });
    setEditableLaneList(initLaneList);
  }, [currentEditFields.schema?.lanes]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      isEditMode: true,
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateCellTypes(editableLaneList as Lane[]));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [editableLaneList, overrideCustomFooterConfig]);

  const maxPreLabelingCount = useMemo(() => {
    const res = editableLaneList.map((lane) => lane?.cellTypes?.[runDesignCardIndex]?.preLabelings?.length ?? 0);
    return Math.max(...res);
  }, [editableLaneList]);

  return (
    <RunDesignTable<(TRunDesignCellType | null)[]>
      className={cn('edit-cell-type__table')}
      tableData={tableData}
      setTableData={updateFromTableData}
      isEdit
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column>
            <span className={cn('edit-cell-type__header-title')}>Cell name</span>
          </RunDesignTable.Column>
          {isSomeCellNameDefined && showFunctionBlock && (
            <RunDesignTable.Column>
              <span className={cn('edit-cell-type__header-title')}>Function</span>
            </RunDesignTable.Column>
          )}

          {Array.from({ length: maxPreLabelingCount }, (_, index) => index).map((index) => (
            <RunDesignTable.Column key={index}>
              <ConsumableHeader className={cn('edit-cell-type__header-title')} isEdit>
                Pre-Labeling {index + 1}
              </ConsumableHeader>
            </RunDesignTable.Column>
          ))}

          {isSomeCellNameDefined && (
            <RunDesignTable.Column>
              <ConsumableHeader className={cn('edit-cell-type__header-title')} withWaves={false} isEdit>
                Pre-Labeling {maxPreLabelingCount ? maxPreLabelingCount + 1 : ''}
              </ConsumableHeader>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {editableLaneList.map((lane, laneIndex) => {
        if (!('sample' in lane)) {
          return <RunDesignTable.Row key={lane.id} />;
        }

        const cellType = lane?.cellTypes?.[runDesignCardIndex];

        const otherLaneList = editableLaneList.filter(({ id }) => lane.id !== id);

        const isAnyCellIndexExist = editableLaneList.some((editableLane) =>
          isDefined(editableLane.cellTypes?.[runDesignCardIndex]?.cellIndex)
        );

        const isAnyConsumableExist = editableLaneList.some((editableLane) =>
          isDefined(editableLane.cellTypes?.[runDesignCardIndex]?.preLabelings)
        );

        const otherLaneCellIndexList =
          otherLaneList?.map((otherLane) => otherLane.cellTypes?.[runDesignCardIndex]?.cellIndex) ?? [];
        const uniqueOtherLaneCellIndexList = otherLaneCellIndexList.reduce<CellIndex[]>((acc, cellIndex) => {
          if (isDefined(cellIndex) && !acc.find((existingCellIndex) => existingCellIndex.id === cellIndex?.id)) {
            acc.push(cellIndex);
          }

          return acc;
        }, []);

        const otherLanePreLabelingList =
          otherLaneList?.flatMap((otherLane) => otherLane.cellTypes?.[runDesignCardIndex]?.preLabelings) ?? [];
        const uniqueOtherLanePreLabelingList = otherLanePreLabelingList.reduce<TRunDesignConsumable[]>(
          (acc, reagent) => {
            if (!isDefined(reagent?.consumable)) {
              return acc;
            }

            if (cellType?.preLabelings?.find((preLabeling) => preLabeling?.consumable.id === reagent.consumable.id)) {
              return acc;
            }

            if (acc.find((existingConsumable) => existingConsumable.id === reagent.consumable.id)) {
              return acc;
            }

            acc.push(reagent.consumable);
            return acc;
          },
          []
        );

        return (
          <RunDesignTable.Row key={lane.id}>
            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypeName
                cellIndex={lane.cellTypes?.[runDesignCardIndex]?.cellIndex}
                laneIndex={laneIndex}
                otherLaneCellIndexList={uniqueOtherLaneCellIndexList}
                onNameChange={handleNameChange}
                laneId={lane.id ?? ''}
                sampleTitle={sampleTitle}
                isSelectionHidden={!isAnyCellIndexExist}
              />
            </RunDesignTable.Column>
            {showFunctionBlock && (
              <RunDesignTable.Column className={cn('edit-cell-type__column')}>
                <EditCellTypeFunction
                  cellType={lane.cellTypes?.[runDesignCardIndex]}
                  laneIndex={laneIndex}
                  onChangeFunction={handleFunctionChange}
                />
              </RunDesignTable.Column>
            )}

            {cellType?.preLabelings?.map((preLabeling, placementIndex) => (
              <RunDesignTable.Column className={cn('edit-cell-type__column')} key={placementIndex}>
                <EditCellTypePreLabeling
                  cellType={lane.cellTypes?.[runDesignCardIndex]}
                  reagent={preLabeling?.consumable as Nullable<Reagent>}
                  laneIndex={laneIndex}
                  placementIndex={placementIndex}
                  laneId={lane.id ?? ''}
                  sampleTitle={sampleTitle}
                  onChangeConsumable={handlePreLabelingChange(laneIndex, placementIndex)}
                  otherConsumableList={uniqueOtherLanePreLabelingList}
                  isSelectionHidden={!isAnyConsumableExist}
                />
              </RunDesignTable.Column>
            ))}

            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypePreLabeling
                cellType={lane.cellTypes?.[runDesignCardIndex]}
                reagent={null}
                laneIndex={laneIndex}
                placementIndex={cellType?.preLabelings?.length ?? 0}
                laneId={lane.id ?? ''}
                sampleTitle={sampleTitle}
                onChangeConsumable={handlePreLabelingChange(laneIndex, cellType?.preLabelings?.length ?? 0)}
                otherConsumableList={uniqueOtherLanePreLabelingList}
                isSelectionHidden={!isAnyConsumableExist}
              />
            </RunDesignTable.Column>

            {/* <ReagentCell */}
            {/*  componentId={reagentListByLane.componentId} */}
            {/*  laneId={lane.id ?? ''} */}
            {/*  onAddNew={onAddNew} */}
            {/*  onPreselectedClick={handlePreselectedClick} */}
            {/*  preselectedUsedReagent={preselectedReagent} */}
            {/*  usedReagentIndex={cellIndex} */}
            {/*  usedReagent={preLabelingReagentData as Nullable<Reagent>} */}
            {/*  isEdit */}
            {/*  withWaves */}
            {/* /> */}
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default EditCellType;
