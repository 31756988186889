import { subMonths, subWeeks, subDays, format, set, formatDuration } from 'date-fns';
import { parse as parseDuration } from 'duration-fns';
import { v4 as uuidv4 } from 'uuid';

import { SortType, TSortType } from './constants';

export const formatDate = (data: string | undefined, formatString = 'MMMM d, yyyy') =>
  data && format(new Date(data), formatString);

export const formatExperimentDuration = (durationValue?: string) => {
  const parsedDuration = durationValue ? parseDuration(durationValue) : { hours: 0, minutes: 0, seconds: 0 };
  return formatDuration(parsedDuration, {
    delimiter: ':',
    zero: true,
    format: ['hours', 'minutes', 'seconds'],
    locale: {
      formatDistance: (_token, distance) => String(distance).padStart(2, '0'),
    },
  });
};

export const formatScanDuration = (durationValue: Duration) =>
  formatDuration(durationValue, {
    delimiter: ' ',
    zero: true,
    format: ['years', 'months', 'days', 'hours', 'minutes'],
    locale: {
      formatDistance: (token, distance) => {
        if (token === 'xYears') {
          return distance > 0 ? `${distance}y` : '';
        }
        if (token === 'xMonths') {
          return distance > 0 ? `${distance}m` : '';
        }
        if (token === 'xDays') {
          return distance > 0 ? `${distance}d` : '';
        }
        if (token === 'xHours') {
          return `${distance}h`;
        }
        return `${distance}m`;
      },
    },
  }).trim();

export const formatNumber = (number?: number | string) =>
  number?.toString().replace(/(\d)(?=(\d\d\d)+(\D|$))/g, '$1,') ?? 0;

/**
 * Formats bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 *
 * @return Formatted string.
 */
export const formatBytesToHumanReadable = (bytes: number) => {
  const threshold = 1024;
  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`;
  }
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  const unitIndex = Math.floor(Math.log(bytes) / Math.log(threshold));
  const formattedSize = (bytes / threshold ** unitIndex).toFixed(2);
  return `${formattedSize}\u00A0${units[unitIndex]}`;
};

export const sortData = (current: string | number, next: string | number) => {
  if (current < next) {
    return -1;
  }
  if (current > next) {
    return 1;
  }
  return 0;
};

export const removeTrailingSlash = (str = '') => str.replace(/^\/+/, '').replace(/\/+$/, '');

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * return value if in limit or min or max
 * @param value
 * @param minValue for min value in limit
 * @param maxValue for max value in limit
 * @param newMinValue optional newMinValue set value if smaller than minValue
 * @param newMaxValue optional newMaxValue set value if bigger than maxValue
 * */
export const limitMinMax = (data: {
  value: number;
  minValue: number;
  maxValue: number;
  newMinValue?: number;
  newMaxValue?: number;
}): number => {
  const { value, minValue, maxValue, newMinValue, newMaxValue } = data;
  let resultValue = value;

  resultValue = resultValue <= minValue ? newMinValue || minValue : resultValue;
  resultValue = resultValue >= maxValue ? newMaxValue || maxValue : resultValue;

  return resultValue;
};

/**
 * return minValue if value is smaller or value if not
 * @param value
 * @param minValue for min value in limit
 * @param newMinValue optional newMinValue set value if smaller than minValue
 * */
export const limitMin = (data: { value: number; minValue: number; newMinValue?: number }): number => {
  const { value, minValue, newMinValue } = data;
  let resultValue = value;

  resultValue = resultValue <= minValue ? newMinValue || minValue : resultValue;

  return resultValue;
};

/**
 * return maxValue if value is bigger or value if not
 * @param value
 * @param maxValue for max value in limit
 * @param newMaxValue optional newMaxValue set value if bigger than maxValue
 * */
export const limitMax = (data: { value: number; maxValue: number; newMaxValue?: number }): number => {
  const { value, maxValue, newMaxValue } = data;
  let resultValue = value;

  resultValue = resultValue >= maxValue ? newMaxValue || maxValue : resultValue;

  return resultValue;
};

/**
 * return value from need start some range in limit
 * @param startValue start range
 * @param endValue end range
 * @param minValue min of limit
 * @param maxValue max of limit
 * */
export const limitRangeMinMax = (data: {
  startValue: number;
  endValue: number;
  minValue: number;
  maxValue: number;
}): number => {
  const { startValue, endValue, maxValue, minValue } = data;
  const size = endValue - startValue;
  if (size > maxValue - minValue) {
    return minValue;
  }

  const limitMinStartValue = limitMin({ minValue, value: startValue });
  const limitMaxEndValue = limitMax({ maxValue, value: endValue });

  const cutFromStart = limitMinStartValue !== startValue ? minValue - startValue : 0;
  if (cutFromStart) {
    return minValue;
  }

  const cutFromEnd = limitMaxEndValue !== endValue ? endValue - maxValue : 0;
  if (cutFromEnd) {
    return startValue - cutFromEnd;
  }

  return startValue;
};

export const isEqualCircle = (obj1: { row: number; column: number }, obj2: { row: number; column: number }): boolean =>
  obj1.row === obj2.row && obj1.column === obj2.column;

export const parseJSON = (str: unknown) => {
  try {
    return JSON.parse(str as string);
  } catch (e) {
    return null;
  }
};

export const removeLastDuplicates = <T extends Record<string, unknown>>(arr: T[], ...keys: string[]): T[] => {
  const filteredArrObj = arr.reduce((acc: Record<string, T>, item) => {
    const itemKey = keys.map((key) => JSON.stringify(item[key])).join('_');
    if (!acc[itemKey]) {
      acc[itemKey] = item;
    }
    return acc;
  }, {});
  return [...Object.values(filteredArrObj)];
};

export const removeDuplicates = <T extends Record<string, unknown>>(arr: T[], ...keys: string[]): T[] => [
  ...new Map(
    arr.map((item) => {
      const itemKey = keys.map((key) => JSON.stringify(item[key])).join('_');
      return [itemKey, item];
    })
  ).values(),
];

export const b64EncodeUnicode = (str: string): string =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(parseInt(p1, 16))));

export const b64DecodeUnicode = (str: string): string => {
  try {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((c) => {
          const str16 = `00${c.charCodeAt(0).toString(16)}`;
          const result = `%${str16.slice(-2)}`;
          return result;
        })
        .join('')
    );
  } catch {
    return 'null';
  }
};

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isFiniteNumber = (value: unknown): value is number => isNumber(value) && Number.isFinite(value);

export const titleCase = (value: string) =>
  value.replace(/^_*(.)|_+(.)/g, (s: string, c: string, d: string) => (c ? c.toUpperCase() : ` ${d.toUpperCase()}`));

export const addWordBreakAfterUnderscores = (content: string) =>
  // u200B is a zero-width space, which is used to prevent
  // the wordbreak from being visible.
  content.replaceAll('_', '_\u200B');

export const removeZeroWidthSpace = (content: string) => content.replaceAll(/\u200B/g, '');

export const getColorWithLuminance = (hex: string, luminance = 0) => {
  // validate hex string
  let validatedHex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    validatedHex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    const startPos = i * 2;
    const endPos = startPos + 2;
    c = parseInt(validatedHex.substring(startPos, endPos), 16);
    c = Math.round(Math.min(Math.max(0, c + c * luminance), 255)).toString(16);
    rgb += `00${c}`.substring(c.length);
  }
  return rgb;
};

export const hexToHSL = (hex: string): Nullable<{ h: number; s: number; l: number }> => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return null;
  }

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h: Nullable<number> = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
    default:
      h = null;
      break;
  }

  if (!isNumber(h)) return null;

  h /= 6;

  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
};

export const HSLToHex = (hsl: { h: number; s: number; l: number }): string => {
  const { h, s, l } = hsl;

  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const toFixed = (val: number, fixedVal = 2): number => +val.toFixed(fixedVal);

export const roundPercentage = (val: number, decimals = 2) =>
  val.toLocaleString('en-US', {
    maximumFractionDigits: decimals,
  });

export const getKeyName = (...args: string[]): string => args.join('_');

export const keyNameFabric = (separator: string) =>
  [(...args: string[]): string => args.join(separator), (str: string): string[] => str.split(separator)] as const;

export const toSnakeCase = (str = '') =>
  str
    .trim()
    .replace(/[\s.]+/g, '_')
    .replace(/[()]/g, '')
    .toLowerCase();

export const isObject = (item: unknown): item is Record<string, unknown> =>
  !!item && typeof item === 'object' && !Array.isArray(item);

export function uniqueKeyForDuplicates<T>(arr: T[]): { id: string; data: T }[] {
  return arr.map((el) => ({ id: uuidv4(), data: el }));
}

export const isTouchScreen = () =>
  typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export function sortByKey<T extends Record<string, unknown>>(
  arr: T[],
  key: keyof T,
  sortType: TSortType = SortType.asc
): T[] {
  const multiplierOfSortType = sortType === SortType.desc ? -1 : 1;

  return arr.sort((a, b) => {
    if (key in a && a[key] < b[key]) {
      return -1 * multiplierOfSortType;
    }
    if (a[key] > b[key]) {
      return 1 * multiplierOfSortType;
    }

    return 0;
  });
}

export const transformObjectToDeepLevel = (obj: Record<string, any>) => {
  let res = {};
  if (obj) {
    res = { ...obj };
  }
  Object.entries(res).forEach(([keysStr, value]) => {
    const keyArr = keysStr.split('.');
    if (keyArr.length === 1 || keysStr.includes('[')) {
      return;
    }
    keyArr.reduce((acc, key, index) => {
      if (index === keyArr.length - 1) {
        acc[key] = value;
        return acc;
      }
      acc[key] = acc[key] ?? {};
      const deepAcc = acc[key];
      return deepAcc;
    }, obj);

    delete obj[keysStr];
  });

  return obj;
};

export const mergeDeep: <T extends Nullable<Record<string, T>>>(target?: T, ...sources: unknown[]) => T | undefined = (
  target,
  ...sources
) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
};

export function arrToMapByKeys<T extends Record<string, unknown>>(arr: T[], ...keys: string[]): Record<string, T> {
  const res = arr.reduce((acc: Record<string, T>, item) => {
    const itemKey = keys.map((key) => (item[key] ?? '').toString()).join('_');
    acc[itemKey] = item;
    return acc;
  }, {});
  return res;
}

// general usage of this function with [].filter of not null/undefined values for correct work with typescript
export function isNotEmptyElement<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined && !Number.isNaN(value);
}

export function groupBy<T>(arr: T[], callback: (e: T, i: number, arr: T[]) => unknown): Record<string, T[]> {
  const result: Record<string, T[]> = {};
  arr.forEach((el, index, mainArr) => {
    const str = callback(el, index, mainArr)?.toString();
    if (!str) {
      return;
    }

    if (!result[str]) {
      result[str] = [];
    }
    result[str].push(el);
  });

  return result;
}

export function repeatKeys(obj: Record<string, number>) {
  return Object.entries(obj).reduce((acc: number[], [key, value]) => {
    acc.push(...Array.from({ length: value }, () => Number(key)));
    return acc;
  }, []);
}

export const formatTime = (timeStr: string) =>
  new Date(timeStr).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  });

export const isKeyOf = <T extends object>(possibleKey: unknown, object: T): possibleKey is keyof T => {
  if (typeof possibleKey !== 'string') {
    return false;
  }

  return possibleKey in object;
};

export function getFilteredObj<T extends Record<string, unknown>>(item: T, global: T) {
  if (!item || typeof item !== 'object') {
    return null;
  }
  const resObj: Record<string, unknown> = {};
  let isEmpty = true;
  Object.keys(item).forEach((key) => {
    if (item[key] !== global[key]) {
      isEmpty = false;
      resObj[key] = item[key];
    }
  });

  return isEmpty ? null : (resObj as T);
}

export function isSomeObjValueDiff<T extends Record<string, unknown>>(laneSettings: T, globalSettings: T) {
  return Object.keys(laneSettings ?? {}).some((key) => laneSettings?.[key] !== globalSettings?.[key]);
}

export const getDateBefore = (timeCode = '') => {
  const [months = '', weeks = '', days = ''] = timeCode.split('-');
  const dateBeforeMonths = subMonths(new Date(), Number(months));
  const dateBeforeMonthsAndWeeks = subWeeks(dateBeforeMonths, Number(weeks));
  const dateBeforeMonthsAndWeeksAndDays = subDays(dateBeforeMonthsAndWeeks, Number(days));
  return timeCode?.trim()
    ? format(
        set(dateBeforeMonthsAndWeeksAndDays, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      )
    : undefined;
};
