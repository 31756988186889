import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useDrag, useDrop } from 'react-dnd';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import icons from '@/components/common/icons';
import { CellKilling, Placement } from '@/graphql/API';
import { DRAGGABLE_TYPES, isNumber } from '@/helpers';
import { isCellKillingComponent, isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import styles from './SchemaComponent.module.scss';

const cn = classNames.bind(styles);

type TSchemaComponentProps = {
  className?: string;
  scanLabel: string;
  isCurrent: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  isCellKillingSelected: boolean;
  component: TRunDesignComponent;
  cellKillingOnTimelineData: {
    relativeToIndex: number;
    row: number;
    component: CellKilling;
  } | null;
  componentIndex: number;
  replaceList: {
    onPlaceBefore: (id: string, relativeTo: string) => void;
    onPlaceAfter: (id: string, relativeTo: string) => void;
  };
};

const SchemaComponent: FC<TSchemaComponentProps> = ({
  className,
  scanLabel,
  isCurrent = false,
  onClick,
  position,
  isCellKillingSelected,
  component,
  cellKillingOnTimelineData,
  componentIndex,
  replaceList: { onPlaceBefore, onPlaceAfter },
}) => {
  const abbreviationName = useMemo(() => getAbbreviationName(component), [component]);

  const withStain = useMemo(
    () => cellKillingOnTimelineData?.component.deliveryStainsAt?.includes(component.id),
    [component, cellKillingOnTimelineData?.component]
  );

  const showScanTrail = useMemo(
    () =>
      !isCellKillingComponent(component) &&
      component.timing.placement !== Placement.SIMULTANEOUS &&
      isNumber(cellKillingOnTimelineData?.relativeToIndex) &&
      componentIndex >= cellKillingOnTimelineData.relativeToIndex,
    [component, cellKillingOnTimelineData]
  );

  const [{ opacity, isDragging }, dragRef] = useDrag(
    () => ({
      type: DRAGGABLE_TYPES.runDesignComponent,
      item: { id: component.id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [component.id]
  );

  const [{ isBeforeOver, isAnyDragging }, dropBeforeRef] = useDrop<
    { id: string },
    unknown,
    { isBeforeOver: boolean; isAnyDragging: boolean }
  >(
    () => ({
      accept: DRAGGABLE_TYPES.runDesignComponent,
      drop: ({ id }) => {
        onPlaceBefore(id, component.id);
      },
      collect: (monitor) => ({
        isBeforeOver: monitor.isOver(),
        isAnyDragging: !!monitor.getItem(),
      }),
    }),
    []
  );

  const [{ isAfterOver }, dropAfterRef] = useDrop<{ id: string }, unknown, { isAfterOver: boolean }>(
    () => ({
      accept: DRAGGABLE_TYPES.runDesignComponent,
      drop: ({ id }) => {
        onPlaceAfter(id, component.id);
      },
      collect: (monitor) => ({
        isAfterOver: monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <button
      className={cn(
        'schema-component',
        {
          'schema-component_current': isCurrent,
          'schema-component_no-scan': !scanLabel,
          'schema-component_cell-killing': isCellKillingComponent(component),
        },
        className
      )}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
          '--column-end': position.columnEnd,
          '--scan-trail-multiplier': cellKillingOnTimelineData ? cellKillingOnTimelineData.row - 1 : 3,
          opacity,
        } as CSSProperties
      }
      ref={dragRef}
    >
      {isAnyDragging && !isDragging && !isCellKillingComponent(component) && (
        <div className={cn('schema-component__drop-wrap')}>
          <div className={cn('schema-component__drop-zone', { '_is-over': isBeforeOver })} ref={dropBeforeRef}>
            <div className={cn('schema-component__drop', 'schema-component__drop_before')}>
              <icons.ArrowRightIcon className={cn('schema-component__drop-icon')} />
            </div>
          </div>
          <div className={cn('schema-component__drop-zone', { '_is-over': isAfterOver })} ref={dropAfterRef}>
            <div className={cn('schema-component__drop', 'schema-component__drop_after')}>
              <icons.ArrowRightIcon className={cn('schema-component__drop-icon')} />
            </div>
          </div>
        </div>
      )}
      <div className={cn('schema-component__wrap')}>
        <div className={cn('schema-component__step', 'schema-component__incubation')}>
          <span className={cn('schema-component__name')}>{component.name}</span>
          <span className={cn('schema-component__name', 'schema-component__name_abbreviation')}>
            {abbreviationName}
          </span>
          {isIncubationComponent(component) && component.hasTreatment && (
            <span>
              <br />
              +TRT
            </span>
          )}
          <div className={cn('schema-component_with-stain')}>
            {withStain && component.timing.placement !== Placement.SIMULTANEOUS && (
              <icons.StainIcon width={10} height={15} />
            )}
          </div>
        </div>
        {scanLabel && (
          <div
            className={cn('schema-component__step', 'schema-component__scan', {
              'schema-component__scan_trail': showScanTrail,
              'schema-component__scan_cell-killing-selected': isCellKillingSelected,
            })}
          >
            {scanLabel}
          </div>
        )}
      </div>
    </button>
  );
};

export default SchemaComponent;
