import { FC, memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import ControlPanel from '@/components/Layout/ControlPanel';
import Nav from '@/components/Layout/Header/Nav';

import * as queries from '@/graphql/queries';
import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { headerActions } from '@/store/slices/header';

import styles from './InstrumentDashboard.module.scss';
import InstrumentPageHeader from './components/InstrumentPageHeader';
import LiveSensors from './components/LiveSensors';
import HistoricalSensors from './components/HistoricalSensors';

const cn = classnames.bind(styles);

export enum EInstrumentTabId {
  liveSensors = 'liveSensors',
  historicalSensors = 'historicalSensors',
}

const InstrumentDashboard: FC = () => {
  const appDispatch = useAppDispatch();
  const [searchParam, setSearchParam] = useSearchParams();

  const instrumentId = useParamsInstrumentId();

  const { data: instrumentData } = useQuery(queries.instrumentById, {
    variables: {
      instrumentId,
    },
  });

  const tabPanels = [
    {
      id: EInstrumentTabId.liveSensors,
      title: 'Live Sensors',
      content: <LiveSensors />,
    },
    {
      id: EInstrumentTabId.historicalSensors,
      title: 'Historical Sensors',
      content: <HistoricalSensors />,
    },
  ];

  const getSelectedIndex = () => {
    const tabId = searchParam.get('tab') ?? '';
    return tabPanels.findIndex((tab) => tab.id === tabId);
  };

  const getSelectedTab = () => {
    const tabIndex = getSelectedIndex();
    return tabIndex === -1 ? 0 : tabIndex;
  };

  const handleTab = (index: number) => {
    if (!index && searchParam.has('tab')) {
      searchParam.delete('tab');
    } else {
      searchParam.set('tab', tabPanels[index].id);
    }

    setSearchParam(searchParam);
  };

  useEffect(() => {
    appDispatch(headerActions.setNewLink({ title: 'instruments', link: '/instruments' }));
  }, []);

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <InstrumentPageHeader instrument={instrumentData?.instrumentById} className={cn('content-block')} />
        <Tabs
          id="tabs"
          selectedTabClassName={cn('tabs__item_selected')}
          selectedTabPanelClassName={cn('dashboard__tab-panel_selected')}
          selectedIndex={getSelectedTab()}
          onSelect={(index) => handleTab(index)}
          className={cn('dashboard__tabs')}
        >
          <ControlPanel size="small">
            <ControlPanel.StickyReveal>
              <Nav />
            </ControlPanel.StickyReveal>
            <ControlPanel.StickyContent>
              <TabList data-test-id="tabs" className={cn('tabs')}>
                {tabPanels.map((tab, index) => (
                  <Tab
                    key={`tab__${tab.id}`}
                    data-test-id={`tab__${tab.id}`}
                    className={cn('tabs__item')}
                    tabIndex={`${index}`}
                  >
                    {tab.title}
                  </Tab>
                ))}
              </TabList>
            </ControlPanel.StickyContent>
          </ControlPanel>
          {tabPanels.map((panel) => (
            <TabPanel key={panel.id} className={cn('dashboard__tab-panel')}>
              {panel.content}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default memo(InstrumentDashboard);
