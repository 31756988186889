import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { isDefined } from '@/helpers/typeGuards';

import { CagingSettingsItem } from '@/graphql/API';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import { TLaneCagingSettings } from '@/pages/experiment-run-design/CagingSettings/components/EditSettings/types';
import {
  getLanesCagingSettingsFromSaved,
  prepareLanesCagingSettingsData,
} from '@/pages/experiment-run-design/CagingSettings/helpers';

import { useOpenModal } from '../useOpenModal';
import { useAppDispatch } from '../useAppDispatch';

type TCagingSettingsContext = {
  selectedSetting: Nullable<string>;
  setSelectedSetting: Dispatch<SetStateAction<Nullable<string>>>;
  isCagingSettingsModalOpen: boolean;
  handleCagingSettingsModalOpen: (settingName?: string) => void;
  handleCagingSettingsModalClose: () => void;
  isViewAdvancedActive: boolean;
  handleViewAdvancedClick: () => void;
  newGlobalSettings: Nullable<CagingSettingsItem>;
  setNewGlobalSettings: Dispatch<SetStateAction<Nullable<CagingSettingsItem>>>;
  newLaneCagingSettings: Nullable<TLaneCagingSettings>[];
  setNewLaneCagingSettings: Dispatch<SetStateAction<Nullable<TLaneCagingSettings>[]>>;
  resetLaneSettingsToGlobal: (globalSettings?: CagingSettingsItem) => void;
  resetToPreviousLaneSettings: () => void;
  isSomeCagingSettingsErrorRef: MutableRefObject<boolean>;
  saveNewCagingSettings: () => void;
  resetAllLastChanges: () => void;
  isGlobalSettingsMode: boolean;
  switchGlobalSettingsMode: () => void;
};

const CagingSettingsContext = createContext<TCagingSettingsContext>({} as TCagingSettingsContext);

export const useCagingSettingsContext = (): TCagingSettingsContext => useContext(CagingSettingsContext);

type TCagingSettingsContextProviderProps = {
  children: ReactNode;
};

export const CagingSettingsContextProvider: FC<TCagingSettingsContextProviderProps> = ({ children }) => {
  const appDispatch = useAppDispatch();

  const lanesCagingSettings = useSelector(experimentRunDesignSelectors.selectLanesCagingSettings);
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const laneLetterMap = useSelector(experimentRunDesignSelectors.selectCurrentLaneLetterMap);

  const [selectedSetting, setSelectedSetting] = useState<Nullable<string>>(null);
  const [isViewAdvancedActive, setIsViewAdvancedActive] = useState(false);

  const [newGlobalSettings, setNewGlobalSettings] = useState<Nullable<CagingSettingsItem>>(null);
  const [newLaneCagingSettings, setNewLaneCagingSettings] = useState<Nullable<TLaneCagingSettings>[]>([]);

  const [isGlobalSettingsMode, setIsGlobalSettingsMode] = useState(false);

  const switchGlobalSettingsMode = () => setIsGlobalSettingsMode((prev) => !prev);

  const isSomeCagingSettingsErrorRef = useRef(false);

  const handleViewAdvancedClick = () => setIsViewAdvancedActive((prev) => !prev);

  const {
    isOpen: isCagingSettingsModalOpen,
    setIsOpen: setIsCagingSettingsModalOpen,
    onClose: handleCagingSettingsModalClose,
  } = useOpenModal();

  const handleCagingSettingsModalOpen = useCallback((settingName?: string) => {
    if (settingName) {
      setSelectedSetting(settingName);
    }
    setIsCagingSettingsModalOpen(true);
  }, []);

  const resetLaneSettingsToGlobal = useCallback(
    (globalSettings?: CagingSettingsItem) => {
      const newOverrideSettings = isDefined(globalSettings) ? globalSettings : globalCagingSettings;
      if (!newOverrideSettings) return;

      const preparedLanesData: Nullable<TLaneCagingSettings>[] = newLaneCagingSettings?.map((laneInfo) => {
        if (!laneInfo) return null;

        const updatedLaneInfo = {
          __typename: 'LaneCagingSettings',
          ...laneInfo,

          overrideSettings: {
            ...laneInfo.overrideSettings,
            ...newOverrideSettings,
          },
        };

        return updatedLaneInfo;
      });

      setNewLaneCagingSettings(preparedLanesData);
    },
    [globalCagingSettings, newLaneCagingSettings]
  );

  const resetToPreviousLaneSettings = useCallback(() => {
    if (!globalCagingSettings) return;

    const settingsByLaneList = getLanesCagingSettingsFromSaved(
      globalCagingSettings,
      laneLetterMap,
      lanesCagingSettings
    );

    setNewLaneCagingSettings(settingsByLaneList);
  }, [globalCagingSettings, laneLetterMap, lanesCagingSettings]);

  const saveNewCagingSettings = useCallback(() => {
    if (!globalCagingSettings) return;

    const clonedTableData = structuredClone(newLaneCagingSettings);

    const preparedLanesData = prepareLanesCagingSettingsData(clonedTableData, isSomeCagingSettingsErrorRef);

    if (isSomeCagingSettingsErrorRef.current) {
      setNewLaneCagingSettings(clonedTableData);
      toast.info('The caging settings are not correct. Please review and try again');

      return;
    }

    appDispatch(
      experimentRunDesignActions.updateGlobalCagingSettings({
        ...globalCagingSettings,
        ...newGlobalSettings,
      })
    );

    appDispatch(experimentRunDesignActions.updateLanesCagingSettings(preparedLanesData));
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
    setIsGlobalSettingsMode(false);
  }, [globalCagingSettings, newGlobalSettings, newLaneCagingSettings]);

  const resetAllLastChanges = useCallback(() => {
    if (!globalCagingSettings) return;

    resetToPreviousLaneSettings();
    setNewGlobalSettings(globalCagingSettings);
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
  }, [globalCagingSettings, lanesCagingSettings]);

  useEffect(() => {
    if (!globalCagingSettings) return;

    setNewGlobalSettings(globalCagingSettings);
  }, [globalCagingSettings]);

  useEffect(() => {
    if (!globalCagingSettings) {
      return;
    }

    const settingsByLaneList = getLanesCagingSettingsFromSaved(
      globalCagingSettings,
      laneLetterMap,
      lanesCagingSettings
    );

    setNewLaneCagingSettings(settingsByLaneList);
  }, [laneLetterMap, globalCagingSettings, lanesCagingSettings]);

  const CagingSettingsContextData = useMemo(
    () => ({
      selectedSetting,
      setSelectedSetting,
      isCagingSettingsModalOpen,
      handleCagingSettingsModalOpen,
      handleCagingSettingsModalClose,
      isViewAdvancedActive,
      handleViewAdvancedClick,
      newGlobalSettings,
      setNewGlobalSettings,
      newLaneCagingSettings,
      setNewLaneCagingSettings,
      resetLaneSettingsToGlobal,
      resetToPreviousLaneSettings,
      isSomeCagingSettingsErrorRef,
      saveNewCagingSettings,
      resetAllLastChanges,
      isGlobalSettingsMode,
      switchGlobalSettingsMode,
    }),
    [
      selectedSetting,
      setSelectedSetting,
      isCagingSettingsModalOpen,
      handleCagingSettingsModalOpen,
      handleCagingSettingsModalClose,
      isViewAdvancedActive,
      handleViewAdvancedClick,
      newGlobalSettings,
      setNewGlobalSettings,
      newLaneCagingSettings,
      setNewLaneCagingSettings,
      resetLaneSettingsToGlobal,
      resetToPreviousLaneSettings,
      isSomeCagingSettingsErrorRef,
      saveNewCagingSettings,
      resetAllLastChanges,
      isGlobalSettingsMode,
      switchGlobalSettingsMode,
    ]
  );

  return <CagingSettingsContext.Provider value={CagingSettingsContextData}>{children}</CagingSettingsContext.Provider>;
};
