import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import { addTooltip } from '@/helpers';
import icons from '@/components/common/icons';

import styles from './SensorListItem.module.scss';

const cn = classnames.bind(styles);

const getSensorTooltip = (sensorName?: string, description?: string) => {
  if (description) {
    return `<div>
      <span style="color: #B5B5B5;">${sensorName}</span>
      </br>
      <span>${description}</span>
      </div>`;
  }

  return sensorName;
};

type TSensorListItemProps = {
  telemetrySensor: TTelemetryByInstrument;
  onClick?: (sensor: TTelemetryByInstrument) => void;
  isClickable?: boolean;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
};

const SensorListItem: FC<TSensorListItemProps> = ({
  telemetrySensor,
  onClick,
  isClickable,
  className,
  selected,
  disabled,
}) => {
  const handleClick = useCallback(() => {
    if (!isClickable || !onClick) return;

    onClick(telemetrySensor);
  }, [isClickable, onClick]);

  return isClickable ? (
    <button
      onClick={handleClick}
      className={cn('sensor', 'sensor_clickable', { sensor_disabled: disabled })}
      disabled={disabled}
    >
      <span
        className={cn('sensor__name', className, { sensor__name_selected: selected })}
        {...addTooltip(telemetrySensor.description, 'top-start')}
      >
        {selected && <icons.CheckMarkIcon className={cn('sensor__icon')} />}
        {telemetrySensor.label}
      </span>
    </button>
  ) : (
    <div className={cn('sensor')}>
      <span
        className={cn('sensor__name', 'sensor__name_dashed', className)}
        {...addTooltip(getSensorTooltip(telemetrySensor.label, telemetrySensor.description), 'top-start')}
      >
        {telemetrySensor.label}
      </span>
      <span>{`${telemetrySensor.value} ${telemetrySensor.postfix}`}</span>
    </div>
  );
};

export default memo(SensorListItem);
