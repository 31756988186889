import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import CustomHeaderWithActions, { TCustomHeaderAction } from '@/components/runDesign/CustomHeaderWithActions';
import icons from '@/components/common/icons';

import headerStyles from '@/pages/experiment-run-design/RunDetails/RunDetails.module.scss';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import CellTypeMax from '@/pages/experiment-run-design/RunDetails/components/CellTypeMax';
import IncubationsTotal from '@/pages/experiment-run-design/RunDetails/components/IncubationsTotal';
import LanesTotal from '@/pages/experiment-run-design/RunDetails/components/LanesTotal';
import RunDetailsMetadata from '@/pages/experiment-run-design/RunDetails/components/RunDetailsMetadata';
import { EDesignStep } from '@/pages/experiment-run-design/types';

import styles from './CustomPageHeader.module.scss';

const cn = classnames.bind({ ...styles, ...headerStyles });

type TCustomPageHeaderProps = {
  onClick: () => void;
  isActive: boolean;
  isEditMode?: boolean;
  openTimelineSettingsModal: () => void;
  isGlobalSettingsMode: boolean;
  resetLaneSettingsToDefault?: () => void;
};

const CustomPageHeader: FC<TCustomPageHeaderProps> = ({
  onClick,
  isActive,
  isEditMode,
  openTimelineSettingsModal,
  isGlobalSettingsMode,
  resetLaneSettingsToDefault,
}) => {
  const { currentStep } = useRunDesignLocation();

  const actions = useMemo<TCustomHeaderAction[]>(() => {
    const result: TCustomHeaderAction[] = [
      {
        title: '',
        onClick,
        isActive,
        isSwitch: true,
        offLabel: 'By Lane',
        onLabel: 'Global',
      },
    ];

    return result;
  }, [onClick, isActive]);

  return isEditMode ? (
    <CustomHeaderWithActions actions={actions} className={cn('header')}>
      <>
        <h3 className={cn('header__title', 'run-details__title')}>Edit CellCage™ Enclosure settings</h3>
        {!isGlobalSettingsMode && (
          <div className={cn('header__subtitle')}>Review and edit CCE settings, organized by lanes, as needed</div>
        )}
        {!isGlobalSettingsMode && (
          <div className={cn('header__buttons')}>
            <button
              type="button"
              onClick={openTimelineSettingsModal}
              className={cn('header__button', 'header__button_timeline')}
            >
              <icons.RunDesignClockIcon className={cn('header__button-icon')} height={30} width={30} />{' '}
            </button>
            {resetLaneSettingsToDefault && isEditMode && (
              <button
                type="button"
                onClick={resetLaneSettingsToDefault}
                className={cn('header__button', 'header__button_reset')}
              >
                <icons.ReloadIcon className={cn('header__button-icon')} />
                <span className={cn('header__button-substring')}>Reset to default</span>
              </button>
            )}
          </div>
        )}
      </>
    </CustomHeaderWithActions>
  ) : (
    <>
      <div className={cn('header__title-wrapper')}>
        <h3 className={cn('header__title', 'run-details__title')}>Review CellCage™ Enclosure settings</h3>
        {!isGlobalSettingsMode && (
          <div className={cn('header__subtitle')}>Review CCE settings, organized by lanes </div>
        )}
        {!isGlobalSettingsMode && (
          <div className={cn('header__buttons')}>
            <button
              type="button"
              onClick={openTimelineSettingsModal}
              className={cn('header__button', 'header__button_timeline')}
            >
              <icons.RunDesignClockIcon className={cn('header__button-icon')} height={30} width={30} />{' '}
            </button>
          </div>
        )}
      </div>
      <div className={cn('run-details__header-data')}>
        <LanesTotal isChangeAllowed={currentStep === EDesignStep.sample} />
        {currentStep === EDesignStep.sample && <CellTypeMax isChangeAllowed />}
        {currentStep === EDesignStep.incubation && <IncubationsTotal isChangeAllowed />}
        <RunDetailsMetadata />
      </div>
    </>
  );
};

export default CustomPageHeader;
