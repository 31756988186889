import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import * as queries from '@/graphql/queries';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import { handlePlotlyResize } from '@/helpers/plotly';
import { throttle } from '@/helpers';

import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import useFlipBlock from '@/hooks/charts/useFlipBlock';
import { TD3DragEvent } from '@/hooks/gates/types';
import { useDebounce } from '@/hooks';
import usePaginatedQuery from '@/hooks/fetchNextTokenFromApollo';

import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';
import styles from './HistoricalSensors.module.scss';

import DateRange from '../DateRange';
import InstrumentCard from '../InstrumentCard';
import SensorListItem from '../SensorListItem';
import SensorByTelemetryTimeline from '../SensorByTelemetryTimeline';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

type TSensorTelemetryPayload = {
  instrumentId: string;
  name: string;
  endDate: Date | null;
  startDate: Date | null;
  limit: number;
  startToken: null;
};

const currentDate = endOfDay(new Date());
const defaultStartDate = startOfDay(sub(currentDate, { days: 90 }));

const MIN_WRAPPER_HEIGHT = 250;
const MIN_LIST_BLOCK_HEIGHT = 100;

const HistoricalSensors: FC = () => {
  const instrumentId = useParamsInstrumentId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlotReadyToDisplay, setIsPlotReadyToDisplay] = useState(false);

  const [sensor, setSensor] = useState<Nullable<TTelemetryByInstrument>>(null);

  const preselectedStartDate = searchParams.get('startDate') ?? null;
  const preselectedEndDate = searchParams.get('endDate') ?? null;

  const [startDate, setStartDate] = useState<Date>(
    preselectedStartDate ? new Date(preselectedStartDate) : defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date>(preselectedEndDate ? new Date(preselectedEndDate) : currentDate);

  const prevDateValuesRef = useRef<Nullable<[Date, Date]>>([startDate, endDate]);

  const throttledSetStartDate = throttle((value: Date) => {
    setStartDate(value);
  }, 300);
  const throttledSetEndDate = throttle((value: Date) => {
    setEndDate(value);
  }, 300);

  const requestPayload = useMemo(
    () => ({
      instrumentId,
      name: sensor?.name ?? '',
      endDate: endDate?.toISOString() ?? null,
      startDate: startDate?.toISOString() ?? null,
      limit: 100,
      startToken: null,
    }),
    [instrumentId, sensor?.name, endDate, startDate]
  );
  const debouncedRequestPayload = useDebounce(requestPayload, 300);

  const {
    allItems: sensorTimelineData,
    loading: isLoading,
    error: sensorTimelineDataError,
    fetchData,
  } = usePaginatedQuery<TTelemetryBySensorFromServer>(
    queries.telemetryBySensor,
    debouncedRequestPayload,
    (res) => res?.telemetryBySensor,
    'telemetryBySensor',
    true
  );

  const setSearchParamItem = (searchParamName: string, searchParamValue: string) => {
    if (searchParamValue) {
      searchParams.set(searchParamName, searchParamValue);
    } else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(searchParams);
  };

  const fetchSensorsTelemetry = (payload: Partial<TSensorTelemetryPayload>) => {
    fetchData({
      variables: { ...debouncedRequestPayload, nextToken: null, ...payload },
      notifyOnNetworkStatusChange: true,
    });
  };

  const onZoomChange = useCallback(
    (minXRange: Nullable<Date>, maxXRange: Nullable<Date>) => {
      const newMinX = minXRange ?? defaultStartDate;
      const newMaxX = maxXRange ?? currentDate;

      throttledSetStartDate(newMinX);
      throttledSetEndDate(newMaxX);
    },
    [startDate, endDate]
  );

  const onChangeStartDate = useCallback(
    (date: Nullable<Date>) => {
      if (!date) return;
      setSearchParamItem('startDate', date.toISOString());
      setStartDate(date);
      fetchSensorsTelemetry({ startDate: date });
      prevDateValuesRef.current = [date, endDate ?? currentDate];
    },
    [debouncedRequestPayload, endDate]
  );

  const onChangeEndDate = useCallback(
    (date: Nullable<Date>) => {
      if (!date) return;
      setSearchParamItem('endDate', date.toISOString());
      setEndDate(date);
      fetchSensorsTelemetry({ endDate: date });
      prevDateValuesRef.current = [startDate ?? defaultStartDate, date];
    },
    [debouncedRequestPayload]
  );

  const changeCurrentSensor = (value: TTelemetryByInstrument) => {
    if (sensor && sensor.name === value.name) {
      setSensor(null);
    } else {
      setSensor(value);
      setIsPlotReadyToDisplay(false);
      if (!value?.name) return;
      fetchSensorsTelemetry({ name: value?.name });
    }
  };

  const {
    data,
    loading: isInstrumentTelemetryLoading,
    error: isInstrumentTelemetryError,
  } = useQuery(queries.telemetryByInstrument, {
    variables: {
      instrumentId,
    },
  });

  const isEmptySensorsData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !data?.telemetryByInstrument?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, data?.telemetryByInstrument]
  );

  const {
    flipBlockRef,
    flipBlockClassName,
    flipBlockBackgroundClassName,
    toggleFullScreen,
    isExpandMode,
    isTransitionEnd,
  } = useFlipBlock(() => {
    const contentWrapper = d3Select('#content');
    contentWrapper.style('grid-template-rows', '2fr 1fr');
    handlePlotlyResize();
  }, cn('card_full-screen'));

  useEffect(
    () => () => {
      searchParams.delete('startDate');
      searchParams.delete('endDate');
    },
    []
  );

  useEffect(() => {
    if (isInstrumentTelemetryLoading || isInstrumentTelemetryError) return;
    const firstSensorData = data?.telemetryByInstrument?.[0] ?? null;
    if (!firstSensorData) return;
    changeCurrentSensor(firstSensorData);
  }, [data, isInstrumentTelemetryLoading, isInstrumentTelemetryError]);

  const onDrag = (event: TD3DragEvent) => {
    const timelineWrapper = d3Select('#timeline-wrapper').node() as Element;
    const contentWrapper = d3Select('#content');
    const listBlock = d3Select('#sensor-list').node() as Element;

    const timelineWrapperClientData = timelineWrapper?.getBoundingClientRect() ?? null;
    const listBlockClientData = listBlock?.getBoundingClientRect() ?? null;

    if (timelineWrapperClientData || !listBlockClientData) {
      const newWrapperHeight = timelineWrapperClientData.height + event.y;
      const newListBlockHeight = listBlockClientData.height - event.y;

      const validatedHeight =
        newListBlockHeight < MIN_LIST_BLOCK_HEIGHT || newWrapperHeight < MIN_WRAPPER_HEIGHT
          ? timelineWrapperClientData.height
          : newWrapperHeight;
      contentWrapper.style('grid-template-rows', `${validatedHeight}px 1fr`);
      handlePlotlyResize();
    }
  };

  const handleRectangleDrag = d3Drag<Element, unknown>()
    .on('start', null)
    .on('drag', (event) => {
      onDrag(event);
    })
    .on('end', null);

  useEffect(() => {
    if (!sensor) return;
    const shapesContainer = d3Select(`#handler`).node() as Element;
    d3Select(shapesContainer).call(handleRectangleDrag);

    return () => {
      const Container = d3Select(`#handler`).node() as Element;

      d3Select(Container).on('.drag', null);
    };
  }, [sensor]);

  return (
    <>
      <div onClick={toggleFullScreen} role="presentation" className={flipBlockBackgroundClassName} />
      <InstrumentCard
        className={cn('content-block', 'card', flipBlockClassName, {
          'card_full-screen': isExpandMode,
          'card_transition-end': !isExpandMode && isTransitionEnd,
        })}
        innerRef={flipBlockRef}
      >
        <InstrumentCard.Header title="Historical sensor readings">
          {!isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !isEmptySensorsData && (
            <InstrumentCard.HeaderRightActions>
              <DateRange
                startDate={startDate}
                onChangeStartDate={onChangeStartDate}
                endDate={endDate}
                onChangeEndDate={onChangeEndDate}
                disabled={isLoading}
              />
              {isTransitionEnd && !isExpandMode ? (
                <Button
                  tooltip="Full screen"
                  color="light"
                  className={cn('card__full-screen-button')}
                  onClick={toggleFullScreen}
                  disabled={isExpandMode}
                >
                  <icons.ExpandScreenIcon />
                </Button>
              ) : (
                <Button
                  color="light"
                  className={cn('card__full-screen-button')}
                  disabled={!isExpandMode}
                  onClick={toggleFullScreen}
                >
                  <icons.CloseIcon />
                </Button>
              )}
            </InstrumentCard.HeaderRightActions>
          )}
        </InstrumentCard.Header>
        <InstrumentCard.Content
          isLoading={isInstrumentTelemetryLoading}
          isNoData={!!(isEmptySensorsData || isInstrumentTelemetryError)}
          noDataMessage={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
          className={cn('content', { content_long: !!sensor })}
          id="content"
        >
          {!isTransitionEnd && <Skeleton className={cn('skeleton')} />}

          {sensor && (
            <div id="timeline-wrapper" className={cn('timeline-wrapper')}>
              <SensorByTelemetryTimeline
                selectedSensor={sensor}
                isPlotReadyToDisplay={isPlotReadyToDisplay}
                setIsPlotReadyToDisplay={setIsPlotReadyToDisplay}
                className={cn('timeline', { 'timeline_full-screen': isExpandMode })}
                isLoading={isLoading}
                isError={!!sensorTimelineDataError}
                data={sensorTimelineData}
                onZoomChange={onZoomChange}
                prevDateValuesRef={prevDateValuesRef}
              />
              <div className={cn('resize-handler')}>
                <button id="handler" className={cn('resize-handler__btn')} aria-label="resize">
                  <icons.DotsIcon className={cn('resize-handler__icon')} />
                </button>
              </div>
            </div>
          )}
          <div id="sensor-list" className={cn('list-block', { 'list-block_full-height': !!sensor })}>
            <div className={cn('list-block__list')}>
              {data?.telemetryByInstrument?.map((telemetry: TTelemetryByInstrument) => (
                <SensorListItem
                  onClick={changeCurrentSensor}
                  key={telemetry.name}
                  telemetrySensor={telemetry}
                  isClickable
                  className={cn('list-block__item')}
                  selected={sensor?.name === telemetry.name}
                  disabled={isLoading}
                />
              ))}
            </div>
          </div>
        </InstrumentCard.Content>
      </InstrumentCard>
    </>
  );
};

export default memo(HistoricalSensors);
