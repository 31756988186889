import { FC, MutableRefObject, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { addTooltip, arrToMapByKeys, isSomeObjValueDiff, LANE_LETTER_NAME_LIST } from '@/helpers';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import ConsumableWaves from '@/components/runDesign/ConsumableComponent/ConsumableWaves';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import { CCEType } from '@/graphql/API';

import styles from './ReviewSettings.module.scss';
import { TLaneCagingSettings } from '../EditSettings/types';
import BaseSettingSections from '../BaseSettingSections';
import { formatCCETypeLabel, getChangedLaneSettings, getDefaultHeaderSections } from '../../helpers';
import SettingsInfoPopover from '../SettingsInfoPopover';
import { prepareValidationConfigForAdvancedSettings } from '../EditSettings/helpers';

const cn = classnames.bind(styles);

type TReviewSettings = {
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

const ReviewSettings: FC<TReviewSettings> = ({ cardRef }) => {
  const laneLetterMap = useSelector(experimentRunDesignSelectors.selectCurrentLaneLetterMap);
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const lanesCagingSettings = useSelector(experimentRunDesignSelectors.selectLanesCagingSettings);

  const data = useMemo(() => {
    if (!globalCagingSettings) {
      return [];
    }
    const settingsByLane = arrToMapByKeys(lanesCagingSettings ?? [], 'laneId');
    const settingsList = LANE_LETTER_NAME_LIST.map((letter) => {
      if (!laneLetterMap[letter]) {
        return null;
      }

      const result: TLaneCagingSettings = {
        ...settingsByLane[letter],
        overrideSettings: {
          ...globalCagingSettings,
          ...(settingsByLane[letter]?.overrideSettings ?? {}),
        },
        laneId: letter,
        errors: {},
      };

      return result;
    });

    return settingsList;
  }, [laneLetterMap, globalCagingSettings]);

  const isSomeSubtractiveCCE = useMemo(() => data.some((row) => row?.cceType === CCEType.SUBTRACTIVE_CCE), [data]);

  const sections = useMemo(() => getDefaultHeaderSections(isSomeSubtractiveCCE), [isSomeSubtractiveCCE]);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('review-settings__table')}
      headerClassName={cn('review-settings__header')}
      header={
        <RunDesignTable.Row>
          <BaseSettingSections sectionsData={sections} cardRef={cardRef} />
        </RunDesignTable.Row>
      }
    >
      {data.map((row, rowIndex) => {
        const isDiff = isSomeObjValueDiff(row?.overrideSettings ?? {}, globalCagingSettings ?? {});
        const isLaneWithSubtractiveCCE = row?.cceType === CCEType.SUBTRACTIVE_CCE && !!row?.cellToSubtract;
        const cellToCageConsumable = row?.cellToCage?.preLabelings?.[0]?.consumable;
        const isReagentCellToCageConsumableConsumable = isReagentConsumable(cellToCageConsumable);
        const cellToSubtractConsumable = row?.cellToSubtract?.preLabelings?.[0]?.consumable;
        const isReagentCellToSubtractConsumableConsumable = isReagentConsumable(cellToSubtractConsumable);
        const changedSettingList = getChangedLaneSettings(row?.overrideSettings, globalCagingSettings);
        const cageSettingsInputValidationConfig = row ? prepareValidationConfigForAdvancedSettings(row) : null;

        return row ? (
          <RunDesignTable.Row key={rowIndex}>
            <RunDesignTable.Column
              className={cn('review-settings__table-column', 'review-settings__table-column_right-gap')}
            >
              <span className={cn('review-settings__data-block')}>{row.magnification}X</span>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('review-settings__table-column')}>
              <span
                {...addTooltip(formatCCETypeLabel(row?.cceType ?? ''))}
                className={cn('review-settings__data-block')}
              >
                {formatCCETypeLabel(row?.cceType ?? '')}
              </span>
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('review-settings__table-column')}>
              <div className={cn('review-settings__data-block', 'review-settings__data-block_aligned-left')}>
                <span {...addTooltip(row?.cellToCage?.cellIndex?.name ?? '')} className={cn('review-settings__text')}>
                  {row?.cellToCage?.cellIndex?.name ?? ''}
                </span>
              </div>
            </RunDesignTable.Column>
            <RunDesignTable.Column
              className={cn('review-settings__table-column', {
                'review-settings__table-column_right-gap': !isSomeSubtractiveCCE,
              })}
            >
              <div className={cn('review-settings__data-block')}>
                <ConsumableWaves
                  opticalDisplay={isReagentCellToCageConsumableConsumable ? cellToCageConsumable?.opticalDisplay : null}
                  className={cn('review-settings__waves')}
                />
              </div>
            </RunDesignTable.Column>
            {isSomeSubtractiveCCE && (
              <>
                <RunDesignTable.Column className={cn('review-settings__table-column')}>
                  {isLaneWithSubtractiveCCE && (
                    <div className={cn('review-settings__data-block', 'review-settings__data-block_aligned-left')}>
                      <span
                        {...addTooltip(row?.cellToSubtract?.cellIndex?.name ?? '')}
                        className={cn('review-settings__text')}
                      >
                        {row?.cellToSubtract?.cellIndex?.name ?? ''}
                      </span>
                    </div>
                  )}
                </RunDesignTable.Column>
                <RunDesignTable.Column
                  className={cn('review-settings__table-column', 'review-settings__table-column_right-gap')}
                >
                  {isLaneWithSubtractiveCCE && (
                    <div className={cn('review-settings__data-block')}>
                      <ConsumableWaves
                        className={cn('review-settings__waves')}
                        opticalDisplay={
                          isReagentCellToSubtractConsumableConsumable ? cellToSubtractConsumable?.opticalDisplay : null
                        }
                      />
                    </div>
                  )}
                </RunDesignTable.Column>
              </>
            )}
            <RunDesignTable.Column className={cn('review-settings__table-column')}>
              <div
                className={cn(
                  'review-settings__table-column_right-gap',
                  'review-settings__table-column_right-gap_reset-settings'
                )}
              >
                <div
                  className={cn('review-settings__data-block', 'review-settings__data-block_with-icon', {
                    'review-settings__data-block_highlight': isDiff,
                  })}
                >
                  {isDiff ? 'Custom settings' : 'Default settings'}
                  {isDiff && (
                    <SettingsInfoPopover
                      changedSettings={changedSettingList}
                      cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
                      title={`The default CCE setting listed below has been manually modified for lane "${row.laneId}"`}
                    />
                  )}
                </div>
              </div>
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        ) : (
          <RunDesignTable.Row key={rowIndex} />
        );
      })}
    </RunDesignTable>
  );
};

export default ReviewSettings;
