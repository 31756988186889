import { FC, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';
import { Popover as TinyPopover } from 'react-tiny-popover';
import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';

import styles from './SettingsInfoPopover.module.scss';
import { TSettingsValidationConfig } from './EditSettings/types';

const cn = classnames.bind(styles);

const defaultMessage = 'The default CCE settings listed below have been manually modified in at least one of the lanes';

type TInfoPopover = {
  title?: string;
  changedSettings?: Record<string, number>;
  cageSettingsInputValidationConfig?: Nullable<Record<TCagingSettingNumberField, TSettingsValidationConfig>>;
  className?: string;
};

const SettingsInfoPopover: FC<TInfoPopover> = ({
  title = defaultMessage,
  changedSettings = {},
  cageSettingsInputValidationConfig,
  className,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverButtonRef = useRef<HTMLDivElement>(null);
  const changedSettingsKeys = Object.keys(changedSettings) as TCagingSettingNumberField[];

  const closePopover = () => setIsPopoverOpen(false);
  const openPopover = () => setIsPopoverOpen(true);

  return (
    <TinyPopover
      isOpen={isPopoverOpen}
      clickOutsideCapture
      align="start"
      ref={popoverButtonRef}
      padding={5}
      boundaryInset={10}
      content={
        <div className={cn('popover__content')}>
          <span className={cn('popover__title')}>{title}</span>
          {!!changedSettingsKeys.length && (
            <ul className={cn('popover__list')}>
              {changedSettingsKeys.map((key) => {
                const placeholder = cageSettingsInputValidationConfig?.[key]?.placeholder ?? '';
                const valueType = cageSettingsInputValidationConfig?.[key]?.valueType ?? '';
                return (
                  <li className={cn('popover__list-item')} key={key}>
                    <span className={cn('popover__list-item-title')}>
                      {placeholder}
                      {valueType ? ` (${valueType})` : ''}
                    </span>
                    <span className={cn('popover__list-item-text')}>{changedSettings?.[key]}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      }
    >
      <button
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        aria-label="info"
        className={cn('info-btn', className)}
      >
        <icons.PencilIcon className={cn('info-btn__icon')} width={22} height={15} />
      </button>
    </TinyPopover>
  );
};

export default SettingsInfoPopover;
