import { FC } from 'react';
import classnames from 'classnames/bind';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';
import { CagingSettingsItem } from '@/graphql/API';

import { themeOptions } from '@/types/theme';
import { TLaneCagingSettings } from '../../../types';
import { prepareValidationConfigForAdvancedSettings } from '../../../helpers';
import styles from '../EditFields.module.scss';
import SettingInput from '../../SettingsInput/SettingsInput';

const cn = classnames.bind(styles);

type TAdvancedSettings = {
  laneCagingSettings: TLaneCagingSettings;
  onChangeNumberField: (field: TCagingSettingNumberField) => (n: number) => void;
  globalCagingSettings?: CagingSettingsItem;
  advancedSettingsList: {
    title: string;
    key: keyof Omit<CagingSettingsItem, '__typename'>;
  }[];
};

const AdvancedSettings: FC<TAdvancedSettings> = ({
  laneCagingSettings,
  onChangeNumberField,
  globalCagingSettings,
  advancedSettingsList,
}) => {
  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(laneCagingSettings);

  return (
    <>
      {advancedSettingsList.map(({ key }, index) => (
        <RunDesignTable.Column
          key={key}
          className={cn('number-field', { 'number-field_right-gap': advancedSettingsList.length - 1 === index })}
        >
          <SettingInput
            onChangeNumberField={onChangeNumberField}
            field={key}
            settings={laneCagingSettings?.overrideSettings}
            defaultValue={globalCagingSettings?.[key] ?? undefined}
            cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
            value={laneCagingSettings?.overrideSettings?.[key] ?? 0}
            errors={laneCagingSettings?.errors ?? {}}
            theme={themeOptions.light}
            isEditMode
            className={cn('number-field__container')}
            popoverMessage="The default for this setting has been manually modified"
          />
        </RunDesignTable.Column>
      ))}
    </>
  );
};

export default AdvancedSettings;
