import { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { useSelector } from 'react-redux';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { TConfigReducerState } from '@/pages/experiment-run-design/ReagentsForAssays/context/reducer';
import { antnAPI } from '@/store/services/annotation';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';

import { isDefined } from '@/helpers/typeGuards';
import { includes } from '@/helpers/enum';
import { isKeyOf } from '@/helpers';
import DeleteButton from './components/DeleteButton';
import EditInformation from './components/EditInformation';
import TitleBlock from './components/TitleBlock';
import FilterBlock from './components/FilterBlock';
import SearchResults from './components/SearchResults';
import SearchBlock from './components/SearchBlock';

import styles from './ReagentsModal.module.scss';

const cn = classNames.bind(styles);

type TReagentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  config: TConfigReducerState;
};

const ReagentsModal: FC<TReagentsModalProps> = ({ isOpen, onClose, config }) => {
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentList, setCurrentList] = useState<TReturnTransformedTypes[]>([]);
  const [selectedReagentId, setSelectedReagentId] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [customSubmitTrigger, setCustomSubmitTrigger] = useState(false);
  const [contentType, setContentType] = useState<'default' | 'info' | 'edit' | 'custom'>('default');
  const [reagentInfo, setReagentInfo] = useState<TReturnTransformedTypes>();
  const [fetchAnnotation] = antnAPI.useLazyFetchBeadByIdQuery();
  const [filterList, setFilterList] = useState<Record<string, string>>({});
  const [isSearchFinished, setIsSearchFinished] = useState(false);
  const [isSelectVisible, setIsSelectVisible] = useState(true);
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);

  const isCustomReagentInfo = useMemo(
    () => contentType === 'custom' || (isDefined(reagentInfo) && isKeyOf('public', reagentInfo) && !reagentInfo.public),
    [reagentInfo, contentType]
  );

  const handleModalOpen = useCallback(async () => {
    // const isValidConsumable = isReagentConsumable(config.current) || isStainConsumable(config.current);
    setIsSearchFinished(false);
    if (!config.current) {
      setIsSelectVisible(true);
      return;
    }

    if (config.current) {
      setSearchValue(config.current.name);
      setSelectedReagentId(config.current.id);
      setIsSubmitDisabled(true);
      setIsSelectVisible(false);
      setIsFilterDisabled(true);

      const { data: reagentData } = await fetchAnnotation(config.current.id);

      if (reagentData) {
        setCurrentList([reagentData]);
      }
    }
  }, [config]);

  const handleModalClose = useCallback(() => {
    setSearchValue('');
    setSelectedReagentId('');
    setCurrentList([]);
    setContentType('default');
    setIsSubmitDisabled(false);
  }, []);

  const handleSearchCancel = () => {
    setSearchValue('');
    setSelectedReagentId('');
    setCurrentList([]);
    setIsSubmitDisabled(false);
    setIsSelectVisible(true);
    setIsFilterDisabled(false);
  };

  const handleCustomAddClick = () => {
    setCustomSubmitTrigger((prev) => !prev);
  };

  const handleDefaultAddClick = () => {
    const currentReagent = currentList.find(({ id }) => id === selectedReagentId);

    if (!currentReagent) {
      throw new Error('Error during setting reagent');
    }

    config.onSelect(currentReagent);
    onClose();
  };

  const handleCustomClick = () => setContentType('custom');

  const handleBackClick = () => setContentType('default');

  const handleEditInfoClick = () => setContentType('edit');

  const handleSaveInfoClick = () => {
    setCustomSubmitTrigger((prev) => !prev);
  };

  const handleInfoClickFactory = (reagentId: string) => () => {
    const reagent = currentList.find(({ id }) => id === reagentId);
    setReagentInfo(reagent);
    setContentType('info');
  };

  const handleFilterChange = (newFilterList: Record<string, string>) => {
    setFilterList(newFilterList);
    setSearchTrigger((prev) => !prev);

    // if no filters are set
    if (Object.values(newFilterList).every((filterValue) => !filterValue)) {
      setIsFilterApplied(false);
      return;
    }

    setIsFilterApplied(true);
  };

  const handleCustomAnnotationAdd = (annotation: TReturnTransformedTypes) => {
    config.onSelect(annotation);
    onClose();
  };

  const handleAnnotationEdit = (annotation: TReturnTransformedTypes) => {
    const currentAnnotationIndex = currentList.findIndex(({ id }) => id === annotation.id);
    setCurrentList((prev) => prev.toSpliced(currentAnnotationIndex, 1, annotation));
    setReagentInfo(annotation);
    setContentType('info');
  };

  const handleAnnotationDelete = (annotation: TReturnTransformedTypes) => {
    setReagentInfo(undefined);

    setContentType('default');

    if (config.current?.id === annotation.id) {
      handleSearchCancel();

      config.onSelect(null);
      return;
    }

    const currentAnnotationIndex = currentList.findIndex(({ id }) => id === annotation.id);
    setCurrentList((prev) => prev.toSpliced(currentAnnotationIndex, 1));
  };

  return (
    <Modal
      sidebar="right"
      onRequestClose={onClose}
      isOpen={isOpen}
      onAfterOpen={handleModalOpen}
      onAfterClose={handleModalClose}
      className={cn('reagents-modal')}
      shouldCloseOnOverlayClick
      parentSelector={() => document.querySelector('#empty-layout') ?? document.body}
      closeTimeoutMS={0}
    >
      <Modal.Header onRequestClose={onClose} className={cn('reagents-modal__header')}>
        <h2 className={cn('reagents-modal__header-text')}>{currentEditFields.name}</h2>
      </Modal.Header>
      <Modal.Content className={cn('reagents-modal__content', { _hidden: contentType !== 'default' })}>
        <TitleBlock />
        <SearchBlock
          onCancel={handleSearchCancel}
          searchValue={searchValue}
          setIsSearchFinished={setIsSearchFinished}
          setReagentList={setCurrentList}
          filterList={filterList}
          className={cn('reagents-modal__search-block')}
          runFromOutsideSearchTrigger={searchTrigger}
        />
        <FilterBlock
          onFilter={handleFilterChange}
          isApplied={isFilterApplied}
          data={currentList}
          isFilterDisabled={isFilterDisabled}
        />
        <div className={cn('reagents-modal__scrollable-block')}>
          <SearchResults
            results={currentList}
            isSearchFinished={isSearchFinished}
            onSelectChange={setSelectedReagentId}
            selectedReagentId={selectedReagentId}
            onInfoClickFactory={handleInfoClickFactory}
            isSelectVisible={isSelectVisible}
          />
        </div>
      </Modal.Content>
      <Modal.Content
        className={cn('reagents-modal__content', { _hidden: !includes(['info', 'edit', 'custom'], contentType) })}
      >
        <TitleBlock
          description=""
          title={
            isCustomReagentInfo
              ? `Custom ${config.custom?.title.replace(/^./, (startLetter) => startLetter.toLowerCase())}`
              : config.custom?.title
          }
          icon={
            <div className={cn('reagents-modal__info')}>
              <icons.InfoRectangularIcon className={cn('reagents-modal__info-icon')} />
            </div>
          }
        />
        <div className={cn('reagents-modal__scrollable-block')}>
          {includes(['info', 'edit', 'custom'], contentType) && (
            <EditInformation
              shouldFormSubmitTrigger={customSubmitTrigger}
              onDataGet={contentType === 'custom' ? handleCustomAnnotationAdd : handleAnnotationEdit}
              setIsLoading={setIsCustomLoading}
              fieldList={contentType === 'custom' ? undefined : reagentInfo}
              contentType={contentType === 'custom' ? 'custom' : (contentType as 'info' | 'edit')}
              isCustom={isCustomReagentInfo}
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('reagents-modal__footer')}>
        <div className={cn('reagents-modal__button-group')}>
          {contentType === 'default' && (
            <Button
              color="light"
              className={cn('reagents-modal__button', 'reagents-modal__close')}
              isOutlined
              onClick={onClose}
            >
              Close
            </Button>
          )}
          {contentType !== 'default' && (
            <Button
              color="light"
              className={cn('reagents-modal__button', 'reagents-modal__close')}
              isOutlined
              onClick={handleBackClick}
            >
              Back
            </Button>
          )}

          {contentType === 'default' && (
            <Button
              color="light"
              isOutlined
              className={cn('reagents-modal__button', 'reagents-modal__add')}
              onClick={handleDefaultAddClick}
              disabled={isSubmitDisabled || !selectedReagentId}
            >
              Add {config.annotationTypeText}
            </Button>
          )}
          {contentType === 'info' && isCustomReagentInfo && (
            <Button
              color="light"
              isOutlined
              className={cn('reagents-modal__button', 'reagents-modal__add')}
              onClick={handleEditInfoClick}
              isLoading={isCustomLoading}
              disabled={isCustomLoading}
            >
              Edit {config.annotationTypeText}
            </Button>
          )}
          {contentType === 'edit' && (
            <Button
              color="light"
              isOutlined
              className={cn('reagents-modal__button', 'reagents-modal__add')}
              onClick={handleSaveInfoClick}
              isLoading={isCustomLoading}
              disabled={isCustomLoading}
            >
              Save {config.annotationTypeText}
            </Button>
          )}
          {contentType === 'custom' && (
            <Button
              color="light"
              isOutlined
              className={cn('reagents-modal__button', 'reagents-modal__add')}
              onClick={handleCustomAddClick}
              isLoading={isCustomLoading}
              disabled={isCustomLoading}
            >
              Add {config.annotationTypeText}
            </Button>
          )}
        </div>

        {contentType === 'default' && !currentList.length && isDefined(config.custom) && (
          <Button
            color="light"
            className={cn('reagents-modal__button', 'reagents-modal__custom')}
            isOutlined
            onClick={handleCustomClick}
          >
            <icons.PlusIcon />
            Custom {config.annotationTypeText}
          </Button>
        )}
        {contentType === 'info' && isDefined(reagentInfo) && isCustomReagentInfo && (
          <DeleteButton
            className={cn('reagents-modal__button')}
            info={reagentInfo}
            onDelete={handleAnnotationDelete}
            setIsLoading={setIsCustomLoading}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReagentsModal;
