import { FC, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { isSomeObjValueDiff } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { CSSProperty } from '@/helpers/interfaces';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import { CCEType } from '@/graphql/API';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import styles from './EditSettings.module.scss';
import EditFields from './components/EditFields';

import { TLaneCagingSettings } from './types';
import { validateCagingSettings } from './helpers';
import BaseSettingSections from '../BaseSettingSections';
import { getHeaderSectionsForEditMode } from '../../helpers';

const cn = classNames.bind(styles);

type TEditSettings = {
  className?: string;
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  isViewMoreActive: boolean;
};

const EditSettings: FC<TEditSettings> = ({ className, cardRef, isViewMoreActive }) => {
  const appDispatch = useAppDispatch();

  const isAdvancedMode = useSelector(experimentRunDesignSelectors.selectIsAdvancedMode);
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);

  const {
    handleCagingSettingsModalOpen,
    newLaneCagingSettings,
    setNewLaneCagingSettings,
    isSomeCagingSettingsErrorRef,
  } = useCagingSettingsContext();

  const isSomeSubtractiveCCE = useMemo(
    () => newLaneCagingSettings.some((row) => row?.cceType === CCEType.SUBTRACTIVE_CCE),
    [newLaneCagingSettings]
  );

  const settingSections = useMemo(
    () => getHeaderSectionsForEditMode(isSomeSubtractiveCCE, isViewMoreActive),
    [isSomeSubtractiveCCE, isViewMoreActive]
  );

  const onTitleClickFactory = useCallback(
    (settingName?: string) => () => {
      handleCagingSettingsModalOpen(settingName);
    },
    [handleCagingSettingsModalOpen]
  );

  const handleChangeTableData = useCallback(
    (setStateData: SetStateAction<Nullable<TLaneCagingSettings>[]>) => {
      let isSomeError = false;
      const newData = Array.isArray(setStateData)
        ? structuredClone(setStateData)
        : structuredClone(setStateData(newLaneCagingSettings));

      newData.forEach((lane) => {
        if (!lane) return;

        const { isValid, errors } = validateCagingSettings(lane);
        lane.errors = { ...errors };

        if (!isValid) {
          isSomeError = true;
        }
      });

      setNewLaneCagingSettings(newData);
      isSomeCagingSettingsErrorRef.current = isSomeError;
    },
    [newLaneCagingSettings]
  );

  useEffect(
    () => () => {
      appDispatch(experimentRunDesignActions.setIsAdvancedMode(false));
    },
    []
  );

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('edit-settings__table', className)}
      headerClassName={cn('edit-settings__header')}
      style={
        {
          '--settings-count': settingSections.length,
        } as CSSProperty
      }
      header={
        <RunDesignTable.Row className={cn('edit-settings__header-row')}>
          <BaseSettingSections
            sectionsData={settingSections}
            cardRef={cardRef}
            onTitleClickFactory={onTitleClickFactory}
          />
        </RunDesignTable.Row>
      }
    >
      {newLaneCagingSettings.map((laneCagingSettings, laneIndex) => {
        const key = laneIndex;
        const isDiff = isSomeObjValueDiff(laneCagingSettings?.overrideSettings ?? {}, globalCagingSettings ?? {});

        return laneCagingSettings ? (
          <EditFields
            key={key}
            isAdvancedMode={isAdvancedMode}
            isViewMoreActive={isViewMoreActive}
            laneCagingSettings={laneCagingSettings}
            laneIndex={laneIndex}
            setTableData={handleChangeTableData}
            isChanged={isDiff}
            isSomeSubtractiveCCE={isSomeSubtractiveCCE}
          />
        ) : (
          <RunDesignTable.Row key={key} />
        );
      })}
    </RunDesignTable>
  );
};

export default EditSettings;
