import { TCustomField } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/EditInformation/types';

const htmlEmailPattern =
  '[Hh][Tt][Tt][Pp][Ss]?:\\/\\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\\d{2,5})?(?:\\/[^\\s]*)?';

export const treatmentCustomFieldList: TCustomField[][] = [
  [
    {
      label: (isCustom: boolean) => (isCustom ? 'Custom treatment name' : 'Treatment name'),
      isRequired: true,
      name: 'name',
      type: 'text',
      placeholder: (isCustom: boolean) => (isCustom ? 'Custom treatment name' : 'Treatment name'),
    },
  ],
];

export const reagentCustomFieldList: TCustomField[][] = [
  [
    {
      label: (isCustom: boolean) => (isCustom ? 'Custom reagent name' : 'Reagent name'),
      isRequired: true,
      name: 'name',
      type: 'text',
      placeholder: (isCustom: boolean) => (isCustom ? 'Custom reagent name' : 'Reagent name'),
    },
  ],
  [
    {
      label: 'Excitation channel (EX)',
      isRequired: true,
      name: 'excitationWavelength',
      type: 'channel-selector',
      category: 'excitation',
    },
    {
      label: 'Emission filter (EM)',
      isRequired: true,
      name: 'detectionWavelength',
      type: 'channel-selector',
      category: 'detection',
    },
  ],
  [
    {
      label: 'Marker',
      name: 'marker',
      type: 'text',
      placeholder: 'Marker name',
    },
    {
      label: 'Marker distribution',
      name: 'markerDistribution',
      type: 'text',
      placeholder: 'Marker distribution',
    },
    {
      label: 'Marker gene id',
      name: 'geneId',
      type: 'text',
      placeholder: 'Marker gene ID',
    },
  ],
  [
    {
      label: 'Source (or Vendor)',
      name: 'vendor',
      type: 'text',
      placeholder: 'Source or vendor name',
    },
    {
      label: 'Catalog number',
      name: 'catalogNumbers',
      type: 'text',
      placeholder: 'Catalog number',
    },
  ],
  [
    {
      label: 'Manufacturer',
      name: 'manufacturerName',
      type: 'text',
      placeholder: 'Reagent original name',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      placeholder: 'Reagent description',
    },
    {
      label: 'URL',
      name: 'url',
      type: 'url',
      placeholder: 'Link to reagent info',
      pattern: htmlEmailPattern,
    },
  ],
];

export const cellNameCustomFieldList: TCustomField[][] = [
  [
    {
      label: (isCustom: boolean) => (isCustom ? 'Custom cell name' : 'Cell name'),
      isRequired: true,
      name: 'name',
      type: 'text',
      placeholder: (isCustom: boolean) => (isCustom ? 'Custom cell name' : 'Cell name'),
    },
    {
      label: 'Organism',
      name: 'organism',
      type: 'text',
      placeholder: 'Organism name',
    },
  ],
  [
    {
      label: 'Cell type',
      name: 'cellType',
      type: 'radio',
      options: [
        {
          label: 'Cell line',
          value: 'Cell line',
        },
        {
          label: 'Primary',
          value: 'Primary',
        },
        {
          label: 'Unknown',
          value: 'Unknown',
        },
      ],
    },
    {
      label: 'Growth properties',
      name: 'growthProperties',
      type: 'radio',
      options: [
        {
          label: 'Adherent',
          value: 'Adherent',
        },
        {
          label: 'Suspension',
          value: 'Suspension',
        },
      ],
    },
  ],
  [
    {
      label: 'Vendor',
      name: 'vendor',
      type: 'text',
      placeholder: 'Cell vendor',
    },
    {
      label: 'Cell line or primary',
      isRequired: true,
      name: 'cellLineOrPrimary',
      type: 'text',
      placeholder: 'Cell line or primary',
    },
    {
      label: 'Morphology',
      name: 'morphology',
      type: 'text',
      placeholder: 'Morphological characteristic',
    },
    {
      label: 'Tissue of origin',
      name: 'tissue',
      type: 'text',
      placeholder: 'Tissue name',
    },
    {
      label: 'Phenotype',
      name: 'phenotype',
      type: 'text',
      placeholder: 'Phenotype',
    },
    {
      label: 'Reporter',
      name: 'reporter',
      type: 'text',
      placeholder: 'Reporter name',
    },
    {
      label: 'Gene modification',
      name: 'geneModification',
      type: 'text',
      placeholder: 'Gene modification',
    },
    {
      label: 'Disease',
      name: 'disease',
      type: 'text',
      placeholder: 'Disease name',
    },
  ],
  [
    {
      label: 'Manufacturer',
      name: 'manufacturerName',
      type: 'text',
      placeholder: 'Original name',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      placeholder: 'Description',
    },
    {
      label: 'URL',
      name: 'url',
      type: 'url',
      placeholder: 'Link to info',
      pattern: htmlEmailPattern,
    },
  ],
];
